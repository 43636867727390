<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="bg-card">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
            <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12">
                <!-- Avatar and name -->
                <div class="flex flex-auto items-center min-w-0">
                    <div class="flex flex-col min-w-0 ml-4">
                        <ng-container *transloco="let t">
                            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">Project Monthly Report</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- Month Selector -->
            <div class="relative flex self-start pt-2 pb-1 pl-5 pr-4 cursor-pointer overflow-hidden rounded-t-xl border border-b-0 bg-default"
                 matRipple
                 [matMenuTriggerFor]="monthsMenu">
                <div class="flex items-center">
                    <div class="overflow-hidden">
                        <div class="font-medium leading-6 truncate">{{selectedMonth}}</div>
                    </div>
                    <div class="flex items-center justify-center pl-2">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                    </div>
                </div>
                <mat-menu
                    #monthsMenu="matMenu"
                    [xPosition]="'before'">
                    <button *ngFor="let month of goBackMonths; let idx = index"
                            mat-menu-item
                            (click)="loadReportMonth(idx)">{{getMonthStartUI(idx)}}
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>

    <!-- Project Selector Section -->
    <div class="mx-4 w-full max-w-screen-xl mt-4 flex flex-col items-center justify-center bg-card shadow-md rounded-xl px-6 py-4">
        <!-- Display selected project name -->
        <h3 *ngIf="selectedProjectName" class="text-lg font-medium mb-4">
            Currently Selected Project: {{ selectedProjectName }}
        </h3>

        <mat-form-field appearance="fill" class="w-full">
            <mat-label>Select Project</mat-label>
            <input type="text"
                   matInput
                   [formControl]="projectControl"
                   [matAutocomplete]="auto"
                   placeholder="Type to search project">
            <mat-autocomplete #auto="matAutocomplete"
                              [displayWith]="displayProject"
                              (optionSelected)="onProjectSelected($event.option.value)">
                <mat-option *ngFor="let project of filteredProjects | async" [value]="project._id">
                    {{ project.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <!-- Summary Section -->
    <div class="mx-4 flex-auto border-t -mt-px pt-4 sm:pt-6">
        <div class="w-full max-w-screen-xl mx-auto">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0">
                <div class="col-span-4 flex flex-col bg-card shadow rounded-2xl p-6">
                    <div class="text-lg font-medium">Summary of {{ selectedMonth }}</div>
                    <div class="grid grid-cols-4 gap-4 mt-6">
                        <div class="flex flex-col items-center justify-center py-8 text-green-800">
                            <div class="text-4xl font-semibold">{{ totalHours.toFixed(2) }}</div>
                            <div class="mt-1 text-lg">Hours to Date</div>
                        </div>
                        <div class="flex flex-col items-center justify-center py-8 text-blue-800">
                            <div class="text-4xl font-semibold">{{ (totalHours * projectedRatio).toFixed(2) }}</div>
                            <div class="mt-1 text-lg">Projected Hours</div>
                        </div>
                        <div class="flex flex-col items-center justify-center py-8 text-green-800">
                            <div class="text-4xl font-semibold">${{ totalEarnings.toFixed(2) }}</div>
                            <div class="mt-1 text-lg">Earnings to Date</div>
                        </div>
                        <div class="flex flex-col items-center justify-center py-8 text-blue-800">
                            <div class="text-4xl font-semibold">${{ (totalEarnings * projectedRatio).toFixed(2) }}</div>
                            <div class="mt-1 text-lg">Projected Earnings</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings By Date Chart -->
            <div class="col-span-4 flex flex-col bg-card shadow rounded-2xl p-6 mt-8">
                <div class="text-lg font-medium">Earning by Date ($)</div>
                <apx-chart [chart]="earningByDate.chart"
                           [series]="earningByDate.series"
                           [labels]="earningByDate.labels"
                           class="h-80 w-full mt-6"></apx-chart>
            </div>

            <!-- Time Entries Table -->
            <div class="col-span-4 flex flex-col bg-card shadow rounded-2xl p-6 mt-8">
                <div class="text-lg font-medium">Time Entries</div>
                <button (click)="exportToExcel()" class="bg-blue-500 text-white rounded px-4 py-2 mt-4">Export to Excel</button>
                <table class="min-w-full mt-4">
                    <thead>
                    <tr>
                        <th class="text-left">User</th>
                        <th class="text-left">Time Spent</th>
                        <th class="text-left">Description</th>
                        <th class="text-left">Timestamp</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let entry of timeEntries">
                        <td>{{ entry.userName }}</td>
                        <td>{{ entry.timeSpent.toFixed(2) }}</td>
                        <td>{{ entry.description }}</td>
                        <td>{{ formatDate(entry.timestamp) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
