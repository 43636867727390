<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="bg-card">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
            <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12">
                <!-- Avatar and name -->
                <div class="flex flex-auto items-center min-w-0">
                    <!--<div class="flex-0 w-16 h-16 rounded-full overflow-hidden">
                        <img
                            class="w-full h-full object-cover"
                            src="assets/images/avatars/brian-hughes.jpg">
                    </div>-->
                    <div class="flex flex-col min-w-0 ml-4">
                        <ng-container *transloco="let t">
                            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">Weekly Report</div>
                        </ng-container>
                        <!--<div class="flex items-center">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:bell'"></mat-icon>
                            <div class="ml-1.5 leading-6 truncate text-secondary">You have 2 new messages and 15 new tasks</div>
                        </div>-->
                    </div>
                </div>
                <!-- Actions -->
                <!--<div class="flex items-center mt-6 sm:mt-0 sm:ml-2 space-x-3">
                    <button
                        class="bg-accent"
                        mat-flat-button
                        [color]="'accent'">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                        <span class="ml-2">Messages</span>
                    </button>
                    <button
                        mat-flat-button
                        [color]="'primary'">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                        <span class="ml-2">Settings</span>
                    </button>
                </div>-->
            </div>
            <!-- Week selector -->
            <div
                class="relative flex self-start pt-2 pb-1 pl-5 pr-4 cursor-pointer overflow-hidden rounded-t-xl border border-b-0 bg-default"
                matRipple
                [matMenuTriggerFor]="weeksMenu">
                <div class="flex items-center">
                    <div class="overflow-hidden">
                        <div class="font-medium leading-6 truncate">{{selectedWeek}}</div>
                    </div>
                    <div class="flex items-center justify-center pl-2">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                    </div>
                </div>
                <mat-menu
                    #weeksMenu="matMenu"
                    [xPosition]="'before'">
                    <button *ngFor="let week of goBackWeeks; let idx = index"
                        mat-menu-item
                        (click)="loadWorklogWeek(idx)">{{getWeekStartUI(idx)}}
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>

    <!-- Main -->
    <div class="flex-auto border-t">
        <div class="w-full max-w-screen-xl mx-auto">
            <!-- User Time Contribution-->
            <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6" style="margin:19px;">
                <div class="flex items-start justify-between">
                    <div class="text-lg font-medium tracking-tight leading-6 truncate">Time Entries</div>
                    <div class="text-lg font-medium tracking-tight text-right truncate">Search:
                        <input
                            matInput
                            style="background-color: #f6f6f6; border-radius: 4px; padding: 5px 19px;"
                            [(ngModel)]="userFilterText">
                        </div>
                </div>
                <div class="mt-8">
                    <div class="-my-3 divide-y">
                        <ng-container>
                            <div class="grid grid-cols-10 py-3">
                                <div class="flex col-span-2 font-medium text-left text-black ql-align-center"
                                    (click)="alphabeticalAscending = !alphabeticalAscending"
                                >Users
                                    <mat-icon *ngIf="alphabeticalAscending"
                                      class="fuse-vertical-navigation-item-arrow icon-size-5"
                                      [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                                    <mat-icon *ngIf="!alphabeticalAscending"
                                      class="fuse-vertical-navigation-item-arrow icon-size-5"
                                      [svgIcon]="'heroicons_solid:chevron-up'"></mat-icon>
                                </div>
                                <div *ngFor="let dayOfWeek of daysOfWeek"
                                    class="col-span-1 font-medium text-center text-black">{{dayOfWeek}}</div>
                                <div class="col-span-1 font-medium text-center text-black">Total</div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let user of filteredUsers(); let i = index">
                            <div class="grid grid-cols-10 py-3" *ngIf="user && !user.disabled && user._id && this.userDict[user._id]"
                                 (click)="this.userDict[user._id] ? this.userDict[user._id].detailActive = !this.userDict[user._id].detailActive : 0 ">
                                <div class="col-span-2 font-medium text-left">{{this.userDict[user._id].name}}</div>
                                <div class="col-span-1 font-medium text-center"
                                     *ngFor="let dayOfWeek of [0,1,2,3,4,5,6]"
                                     [style]="hourFlagger(user, dayOfWeek)"
                                >{{sumOfHoursObj(this.userDict[user._id].hours[dayOfWeek])}}</div>
                                <div class="col-span-1 font-medium text-gray-900 text-center">{{roundToTwoDecimal(this.userDict[user._id].totalHours)}}</div>
                            </div>
                            <ng-container *ngIf="user && user._id && this.userDict[user._id] && this.userDict[user._id].detailActive">
                                <div class="grid grid-cols-10 py-3"
                                     style="background-color: #D3D3D3;"
                                     *ngFor="let projectName of this.userDict[user._id].associatedProjects; let jdx = index"
                                     (click)="this.userDict[user._id].detailActive = !this.userDict[user._id].detailActive">
                                    <div class="col-span-2 font-medium text-left" style="font-size: 12px">→ {{projectName}}</div>
                                    <div class="col-span-1 font-medium text-center"
                                         *ngFor="let dayOfWeek of [0,1,2,3,4,5,6]"
                                    >{{this.userDict[user._id].hours[dayOfWeek][projectName] ? roundToTwoDecimal(this.userDict[user._id].hours[dayOfWeek][projectName]) : 0}}</div>
                                    <div class="col-span-1 font-medium text-gray-900 text-center">{{roundToTwoDecimal(this.userDict[user._id].associatedProjectsHours[jdx])}}</div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
