import {Component, OnDestroy, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {ApexOptions} from "ng-apexcharts";
import {ProjectService} from "../../../core/_services/project.service";
import {Router} from "@angular/router";
import {TimeEntryService} from "../../../core/_services/time-entry.service";
import {UserService} from "../../../core/_services/user.service";
import { MatDialog } from '@angular/material/dialog';
import moment from "moment";
import { BlockMonthDialogComponent } from './block-month-dialog/block-month-dialog.component';
import { SyncMonthDialogComponent } from "./sync-month-dialog/sync-month-dialog.component";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {EngagementService} from "../../../core/_services/engagement.service";

@Component({
  selector: 'app-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss']
})
export class MonthlyReportComponent implements OnInit, OnDestroy {
    monthIndex = 0;

    projects;
    users;
    userDict;
    projectDict;
    timeEntries;
    totalHours;
    totalEarnings;
    projectedRatio;
    engagements;

    goBackMonths = Array(52).fill(0);
    selectedMonth: string = 'Current Month';

    blockMonth;

    chartsLoaded = false;

    //graphs
    earningsDailyGraphData;
    userTimeContributionGraphData;
    projectTimeDistributionGraphData;
    projectEarningDistributionGraphData;

    earningByDate: ApexOptions = {};

    disabled = false;

    // template variables below
    data: any;

    chartProjectEarningDistribution: ApexOptions;
    chartProjectTimeDistribution: ApexOptions;
    chartUserTimeContribution: ApexOptions;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _projectService: ProjectService,
        private _timeEntryService: TimeEntryService,
        private _userService: UserService,
        private _engagementService: EngagementService,
        private _router: Router,
        private dialog: MatDialog,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loadReport();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getMonthStartUI(index?) {
        let startDate;
        if (index) {
            startDate = moment().add(-index, 'M').startOf('month');
        } else {
            startDate = moment().startOf('month');
        }
        return startDate.format('MMMM YYYY');
    }

    checkBlockMonth() {
        const blockMonth = moment().subtract(this.monthIndex, 'M').endOf('month').toISOString();
        if (this.blockMonth >= blockMonth) {
            return false;
        } else {
            return true;
        }
    }

    confirmBlockMonth() {
        const dialogRef = this.dialog.open(BlockMonthDialogComponent, {
            width: '350px',
            data: moment().subtract(this.monthIndex, 'M').endOf('month').toISOString(),
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.updateBlockMonth();
            }
        });
    }

    updateBlockMonth() {
        const blockMonth = moment().subtract(this.monthIndex, 'M').endOf('month').toISOString();
        this._timeEntryService.updateBlockMonth(blockMonth).subscribe(res => {
            if (res.success && res.blockMonth) {
                this.blockMonth = res.blockMonth;
            }
        })
    }

    getBlockMonth() {
        this._timeEntryService.getBlockMonth().subscribe(res => {
            if (res.success && res.blockMonth) {
                this.blockMonth = res.blockMonth;
            }
        });
    }

    confirmSyncMonth() {
        const startOfMonth = moment().subtract(this.monthIndex, 'M').startOf('month').toISOString();
        const endOfMonth = moment().subtract(this.monthIndex, 'M').endOf('month').toISOString();
        const dialogRef = this.dialog.open(SyncMonthDialogComponent, {
            width: '350px',
            data: { startOfMonth, endOfMonth },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.syncMonth(startOfMonth, endOfMonth);
            }
        });
    }

    syncMonth(startDate, endDate) {
        this._timeEntryService.sync(startDate, endDate).subscribe(async res => {
            if (res.success) {
                await this.loadReport();
            }
        });
    }

    getMonthStartQuery(index?) {
        let startDate;
        if (index) {
            startDate = moment().add(-index, 'M').startOf('month');
        } else {
            startDate = moment().startOf('month');
        }
        return startDate.format('YYYY-MM-01');
    }

    loadReport = async () => {
        this.getBlockMonth();
        const startDate = this.getMonthStartQuery(this.monthIndex);
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(startDate).add(1, 'M').startOf('month').format('YYYY-MM-DD');
        const daysInMonth = moment(startDate).daysInMonth();
        const daysPassed = this.monthIndex === 0 ? parseInt(moment().format('D')) : daysInMonth;
        this.projectedRatio = daysInMonth / daysPassed;
        try {
            this.totalHours = 0;
            this.totalEarnings = 0;
            this.userDict = {};
            this.projectDict = {};
            this.earningsDailyGraphData = {
                labels: Array.from({ length: daysInMonth }, (_, i) => ((parseInt(String(i)) + 1).toString())),
                overview: {},
                series: [{
                    data: new Array(daysInMonth).fill(0),
                    name: "Earnings",
                    type: "line"
                }],
            }
            this.projectEarningDistributionGraphData = {
                labels: [],
                series: [],
            }
            this.projectTimeDistributionGraphData = {
                labels: [],
                series: [],
            }
            this.userTimeContributionGraphData = {
                labels: [],
                series: [],
            };

            this._userService.getUsers({}).subscribe(users => {
                this.users = users;
                this.users.forEach(user => {
                    this.userDict[user._id] = {
                        disabled: user.disabled,
                        name: `${user.nameFirst} ${user.nameLast}`,
                        hours: 0,
                        billingType: user.billingType == 'senior' ? 'seniorBillingRate' : user.billingType == 'mid' ? 'midBillingRate' : 'juniorBillingRate',
                    }
                });
                this._projectService.getProjects({}).subscribe(projects => {
                    this.projects = projects;
                    this.projects.map(project => {
                        this.projectDict[project._id] = {
                            name: `${project.name}`,
                            cost: 0,
                            hours: 0,
                            juniorBillingRate: project.juniorBillingRate,
                            midBillingRate: project.midBillingRate,
                            seniorBillingRate: project.seniorBillingRate,
                        };
                    })
                    this._timeEntryService.getTimeEntryRange(start, end).subscribe(timeEntries => {
                        this.timeEntries = timeEntries;
                        this.timeEntries.forEach(timeEntry => {
                            // timeEntry display name fields
                            timeEntry.userName = this.userDict[timeEntry.user] ? this.userDict[timeEntry.user].name : "unknown";
                            timeEntry.projectName = this.projectDict[timeEntry.project].name;

                            // userdata due to issue with unmatched user, in if block
                            let timeEntryCost = 0;
                            if (this.userDict[timeEntry.user]) {
                                // hour
                                this.userDict[timeEntry.user].hours += timeEntry.timeSpent;
                                // cost
                                timeEntryCost = this.projectDict[timeEntry.project][this.userDict[timeEntry.user].billingType] * timeEntry.timeSpent;
                            }
                            //hours
                            this.projectDict[timeEntry.project].hours += timeEntry.timeSpent;
                            this.totalHours += timeEntry.timeSpent;
                            // cost
                            this.projectDict[timeEntry.project].cost += timeEntryCost;
                            this.totalEarnings += timeEntryCost;

                            // chartData
                            const day = parseInt(timeEntry.timestamp.slice(8, 10));
                            this.earningsDailyGraphData.series[0].data[day - 1] += timeEntryCost;
                        });

                        // Earnings by Date
                        this.earningsDailyGraphData.overview.totalHours = this.totalHours.toFixed(2);
                        this.earningsDailyGraphData.overview.projectedHours = (this.totalHours * this.projectedRatio).toFixed(2);
                        this.earningsDailyGraphData.overview.totalEarnings = this.totalEarnings.toFixed(2);
                        this.earningsDailyGraphData.overview.projectedEarnings = (this.totalEarnings * this.projectedRatio).toFixed(2);
                        this.earningsDailyGraphData.series[0].data.forEach(data => data.toFixed(2));

                        this._loadPieCharts();
                    });
                });

                this._engagementService.getEngagementsWithinMonth(start).subscribe(res =>{
                    console.log('engagement response');
                    this.engagements = res;
                })
            })
        } catch (error) {
            console.log('err', error);
        }
    }

    loadReportMonth(index) {
        this.monthIndex = index;
        this.selectedMonth = `${this.getMonthStartUI(this.monthIndex)}`;
        this.loadReport();
    }

    formatTime(time) {
        return moment(time).format('M/DD/YYYY h:mm a');
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private _fixSvgFill(element: Element): void {
        const currentURL = this._router.url;
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    private _loadPieCharts = async () => {
        await Promise.all(Object.keys(this.projectDict).map(key => {
            this.projectEarningDistributionGraphData.labels.push(this.projectDict[key].name);
            this.projectTimeDistributionGraphData.labels.push(this.projectDict[key].name);
            this.projectEarningDistributionGraphData.series.push(parseFloat((this.projectDict[key].cost / this.totalEarnings * 100).toFixed(2)));
            this.projectTimeDistributionGraphData.series.push(parseFloat((this.projectDict[key].hours / this.totalHours * 100).toFixed(2)));
        }));

        await Promise.all(Object.keys(this.userDict).map(key => {
            if (!this.userDict[key].disabled) {
                this.userTimeContributionGraphData.labels.push(this.userDict[key].name);
                this.userTimeContributionGraphData.series.push(parseFloat((this.userDict[key].hours / this.totalHours * 100).toFixed(2)));
            }
        }));

        this._prepareChartData();
    }

    private _prepareChartData(): void {
        this.earningByDate = {
            chart: {
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            colors: ['#64748B', '#94A3B8'],
            dataLabels: {
                enabled: false,
                enabledOnSeries: [0],
                background: {
                    borderWidth: 0
                }
            },
            grid: {
                borderColor: 'var(--fuse-border)'
            },
            labels: this.earningsDailyGraphData.labels,
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            series: this.earningsDailyGraphData.series,
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.75
                    }
                }
            },
            stroke: {
                width: [3, 0]
            },
            tooltip: {
                followCursor: true,
                theme: 'dark'
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    color: 'var(--fuse-border)'
                },
                labels: {
                    style: {
                        colors: 'var(--fuse-text-secondary)'
                    },
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                labels: {
                    offsetX: -16,
                    style: {
                        colors: 'var(--fuse-text-secondary)'
                    },
                    formatter: (value) => {
                        return value.toFixed(0)
                    },
                }
            }
        };

        this.chartProjectEarningDistribution = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#3182CE', '#63B3ED'],
            labels: this.projectEarningDistributionGraphData.labels,
            plotOptions: {
                pie: {
                    customScale: 0.9,
                    expandOnClick: false,
                    donut: {
                        size: '70%'
                    }
                }
            },
            series: this.projectEarningDistributionGraphData.series,
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                theme: 'dark',
                custom: ({
                             seriesIndex,
                             w
                         }): string => `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                                                <div class="w-3 h-3 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
                                                <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                                                <div class="ml-2 text-md font-bold leading-none">${w.config.series[seriesIndex]}%</div>
                                            </div>`
            }
        };

        this.chartProjectTimeDistribution = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#319795', '#4FD1C5'],
            labels: this.projectTimeDistributionGraphData.labels,
            plotOptions: {
                pie: {
                    customScale: 0.9,
                    expandOnClick: false,
                    donut: {
                        size: '70%'
                    }
                }
            },
            series: this.projectTimeDistributionGraphData.series,
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                theme: 'dark',
                custom: ({
                             seriesIndex,
                             w
                         }): string => `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                                                <div class="w-3 h-3 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
                                                <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                                                <div class="ml-2 text-md font-bold leading-none">${w.config.series[seriesIndex]}%</div>
                                            </div>`
            }
        };

        this.chartUserTimeContribution = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#DD6B20', '#F6AD55'],
            labels: this.userTimeContributionGraphData.labels,
            plotOptions: {
                pie: {
                    customScale: 0.9,
                    expandOnClick: false,
                    donut: {
                        size: '70%'
                    }
                }
            },
            series: this.userTimeContributionGraphData.series,
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                theme: 'dark',
                custom: ({
                             seriesIndex,
                             w
                         }): string => `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                                                <div class="w-3 h-3 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
                                                <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                                                <div class="ml-2 text-md font-bold leading-none">${w.config.series[seriesIndex]}%</div>
                                            </div>`
            }
        };
        this.chartsLoaded = true;
        window['Apex'] = {
            chart: {
                events: {
                    mounted: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                    updated: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    }
                }
            }
        };
    }

    exportToExcel() {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.timeEntries.map(entry => ({
            User: entry.userName,
            "Time Spent": entry.timeSpent.toFixed(2),
            Project: entry.projectName,
            Description: entry.description,
            Timestamp: this.formatTime(entry.timestamp)
        })));
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'TimeEntries');
        XLSX.writeFile(wb, 'TimeEntries.xlsx');
    }

    formatDate(date: Date): string {
        return moment(date).format('MM/DD/YYYY');
    }

}
