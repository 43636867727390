<div class="text-xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">
Create User
</div>
<div mat-dialog-content *ngIf="data">
    <form
        class="mt-8"
        [formGroup]="editProfileForm"
        #editProfileNgForm="ngForm">
        <!-- Name field -->
        <div style="display:flex; justify-content: space-between;">
            <mat-form-field class="" style="width: 50%">
                <mat-label>First Name</mat-label>
                <input
                    id="nameFirst"
                    matInput
                    [formControlName]="'nameFirst'">
                <mat-error *ngIf="editProfileForm.get('nameFirst').hasError('required')">
                    Full name is required
                </mat-error>
            </mat-form-field>
           <mat-form-field class="" style="width: 50%">
                <mat-label>Last Name</mat-label>
                <input
                    id="nameLast"
                    matInput
                    [formControlName]="'nameLast'">
                <mat-error *ngIf="editProfileForm.get('nameLast').hasError('required')">
                    Last name is required
                </mat-error>
            </mat-form-field>
        </div>
        <!-- Email field -->
        <mat-form-field class="w-full">
            <mat-label>Email address</mat-label>
            <input
                id="email"
                matInput
                [formControlName]="'email'">
            <mat-error *ngIf="editProfileForm.get('email').hasError('required')">
                Email address is required
            </mat-error>
            <mat-error *ngIf="editProfileForm.get('email').hasError('email')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
        <!-- Jira Username, AccountId-->
        <div style="display:flex; justify-content: space-between;">
            <mat-form-field class="" style="width: 50%">
                <mat-label>jiraUsername</mat-label>
                <input
                    id="jiraUsername"
                    matInput
                    [formControlName]="'jiraUsername'">
                <mat-error *ngIf="editProfileForm.get('jiraUsername').hasError('required')">
                    jiraUsername is required
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex justify-between items-center mb-3">
            <div style="color: #1e293b; font-weight: 500;">Jira Accounts</div>
            <button
                class="mt-0.5"
                mat-flat-button
                [color]="'accent'"
                (click)="addJiraAccount()">
                Add JIRA Account
                <!--                <mat-icon class="add-course-btn">add</mat-icon>-->
            </button>
        </div>

        <div class="flex flex-row" style="align-items: center"
             *ngFor="let jiraAccountId of editProfileForm.get('jiraAccountIds').controls; let i=index">
            <div style="width: 3%"></div>
            <mat-form-field style="width: 45%;">
                <mat-label>Account</mat-label>
                <div class="flex flex-row justify-items-start">
                    <mat-select [formControl]="jiraAccountId.get('account')">
                        <mat-option *ngFor="let option of accountOptions" [value]="option._id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </div>
                <mat-error *ngIf="jiraAccountId.get('jiraUserId').hasError('required')">
                    jiraUserId is required
                </mat-error>
            </mat-form-field>
            <mat-form-field style="width: 45%; margin-left: 5px;">
                <mat-label>JIRA Account ID</mat-label>
                <input
                    matInput
                    [formControl]="jiraAccountId.get('jiraUserId')"
                    placeholder="Jira User Id"
                />
            </mat-form-field>
            <button mat-icon-button (click)="removeJiraAccount(i)" matTooltip="Remove" [color]="'warn'">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>

        <!-- SlackId field -->
        <mat-form-field class="w-full">
            <mat-label>Slack Id</mat-label>
            <input
                id="slackId"
                matInput
                [formControlName]="'slackId'">
            <mat-error *ngIf="editProfileForm.get('slackId').hasError('required')">
                slackId is required
            </mat-error>
        </mat-form-field>

        <mat-checkbox
            class="-ml-2"
            [color]="'primary'"
            [formControlName]="'slackDailyCheckAlert'">
            <span>Daily Timekeeper Alert</span>
        </mat-checkbox>

        <mat-checkbox
            class="-ml-2"
            [color]="'primary'"
            [formControlName]="'disabled'">
            <span>disabled</span>
        </mat-checkbox>

        <div style="color: #1e293b">Roles</div>
        <div class="text-red-400 text-center mb-2" *ngIf="!editProfileForm.value.roles.length">Please select a role for the user.</div>
        <ng-container *ngIf="editProfileForm.value.roles && editProfileForm.value.roles.length">
            <div class="flex flex-wrap items-center mx-1 mt-2">
                <ng-container *ngFor="let role of editProfileForm.value.roles; let roleIdx = index">
                    <div class="flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                        <div>
                            {{roleDict[role.role]}}
                        </div>
                        <mat-icon
                            class="ml-1 icon-size-4 cursor-pointer"
                            [svgIcon]="'heroicons_solid:x-circle'"
                            (click)="removeRole(roleIdx)"></mat-icon>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <mat-form-field class="w-full" *ngIf="roleOptions && roleOptions.length">
            <!--            <mat-label>Add Roles</mat-label>-->
            <mat-select
                [value]="''"
                [formControlName]="'role'"
                #roleSelector="matSelect"
                (selectionChange)="addRole($event.value)"
            >
                <mat-select-trigger>
                    <span class="flex items-center">
                        <span>{{roleSelector.triggerValue}}</span>
                    </span>
                </mat-select-trigger>
                <mat-option disabled [value]="''">Select Role to Add</mat-option>
                <mat-option *ngFor="let roleOption of roleOptions" [value]="roleOption">{{roleDict[roleOption]}}</mat-option>
            </mat-select>
        </mat-form-field>
<!--        <button mat-flat-button-->
<!--                (click)="addRole()"-->
<!--                [color]="'primary'"-->
<!--                class="w-15"-->
<!--                style="margin-left: 5%">-->
<!--            &lt;!&ndash;                <mat-icon&ndash;&gt;-->
<!--            &lt;!&ndash;                    class="icon-size-5"&ndash;&gt;-->
<!--            &lt;!&ndash;                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>&ndash;&gt;-->
<!--            &lt;!&ndash;                <span class="ml-2">Add</span>&ndash;&gt;-->
<!--            Add-->
<!--        </button>-->
        <!-- Submit button -->
        <!-- Bill Type, Hourly Cost-->
        <!-- Bill Type, Hourly Cost-->
        <div style="display:flex; justify-content: space-between;">
            <mat-form-field class="w-full">
                <mat-label>Billing Role</mat-label>
                <div class="flex flex-row justify-items-start">
                    <mat-select [formControlName]="'billingRole'">
                        <mat-option disabled [value]="''">Select Billing Role</mat-option>
                        <mat-option *ngFor="let option of billingRoleOptions" [value]="option._id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </div>
            </mat-form-field>
            <!--            <mat-form-field class="" style="width: 50%">-->
            <!--                <mat-label>Billing Type</mat-label>-->
            <!--                <input-->
            <!--                    id="billingType"-->
            <!--                    matInput-->
            <!--                    [formControlName]="'billingType'">-->
            <!--                <mat-error *ngIf="editProfileForm.get('billingType').hasError('required')">-->
            <!--                    billingType is required-->
            <!--                </mat-error>-->
            <!--            </mat-form-field>-->
            <!--            <mat-form-field class="" style="width: 50%">-->
            <!--                <mat-label>Hourly Cost</mat-label>-->
            <!--                <input-->
            <!--                    id="hourlyCost"-->
            <!--                    matInput-->
            <!--                    [formControlName]="'hourlyCost'">-->
            <!--                <mat-error *ngIf="editProfileForm.get('hourlyCost').hasError('required')">-->
            <!--                    hourlyCost is required-->
            <!--                </mat-error>-->
            <!--            </mat-form-field>-->
        </div>

        <button
            class="fuse-mat-button-large w-full mt-6"
            mat-flat-button
            [color]="'primary'"
            [disabled]="editProfileForm.disabled || !editProfileForm.value.roles.length"
            (click)="createUser()">
                    <span *ngIf="!editProfileForm.disabled">
                        Create User
                    </span>
            <mat-progress-spinner
                *ngIf="editProfileForm.disabled"
                [diameter]="24"
                [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
        <button
            class="fuse-mat-button-large w-full mt-0.5"
            mat-flat-button
            [color]="'accent'"
            (click)="onClose()">
                    <span>
                        Cancel
                    </span>
        </button>
    </form>
</div>
<!--<div mat-dialog-actions>
    <button mat-button (click)="onClose()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>-->
