import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormArray, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../core/_services/user.service";
import {AuthService} from "../../../../core/auth/auth.service";
import {ProjectService} from "../../../../core/_services/project.service";
import {AccountService} from "../../../../core/_services/account.service";

export interface DialogData {
    _id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit, OnDestroy{
    @ViewChild('editProjectForm') editProjectNgForm: NgForm;

    editProjectForm: UntypedFormGroup;
    codes = [];
    currentUser;
    view = "edit";
    accountOptions: any;

    constructor(
        private _accountService: AccountService,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _userService: UserService,
        private _projectService: ProjectService,
        public dialogRef: MatDialogRef<EditProjectComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {}

    onClose(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this._accountService.getAccounts({}).subscribe(accounts => {
            this.accountOptions = accounts;
        })

        if(this.data && this.data.formType && this.data.formType === "new"){
            this.view = "new";
        }
        this.editProjectForm = this._formBuilder.group({
            name      : ['', Validators.required],
            description    :[''],
            estimatedCost     : [''],
            juniorBillingRate    :[''],
            midBillingRate    :[''],
            seniorBillingRate    :[''],
            jiraProjectIds    : this._formBuilder.array([]),
            code    :[''],
            }
        );

        console.log(this.data);
        if (this.data && this.view === "edit") {
            this.data.accounts.map(account => {
                this.addJiraAccount(account.account, account.projectId);
            });
            this.editProjectForm.controls.name.setValue(this.data.name);
            this.editProjectForm.controls.description.setValue(this.data.description);
            this.editProjectForm.controls.estimatedCost.setValue(this.data.estimatedCost);
            this.editProjectForm.controls.juniorBillingRate.setValue(this.data.juniorBillingRate);
            this.editProjectForm.controls.midBillingRate.setValue(this.data.midBillingRate);
            this.editProjectForm.controls.seniorBillingRate.setValue(this.data.seniorBillingRate);
            console.log(this.data.codes);
            if(this.data.codes){
                this.codes = this.data.codes;
            }
        }
    }

    ngOnDestroy(): void {
        console.log('Edit Project Closed');
    }

    addJiraAccount(accoundId?, projectId?) {
        const jiraAccount = this._formBuilder.group({
            account: [accoundId || '', Validators.required],
            projectId: [projectId || '', Validators.required],
        });

        this.jiraAccountIds.push(jiraAccount);
    }

    // Helper function to remove an item from the jiraAccountIds array
    removeJiraAccount(index: number) {
        this.jiraAccountIds.removeAt(index);
    }

    // Getter for easier access to the jiraAccountIds array
    get jiraAccountIds() {
        return this.editProjectForm.get('jiraProjectIds') as FormArray;
    }

    addCode(){
        this.codes.push(this.editProjectForm.value.code);
        this.editProjectForm.controls.code.setValue("");
    }

    removeCode(removedCode){
        this.codes = this.codes.filter(code => {
            return code !== removedCode;
        });
    }

    updateProject = async () => {
        if(this.view === "new"){
            try {
                const params = {
                    ...this.editProjectForm.value,
                    codes: this.codes,
                    accounts: this.jiraAccountIds.value,
                };
                delete params.code;
                await this._projectService.createProject(params).subscribe(data => {
                    console.log('created project');
                    console.log(data);
                    //TODO if admin2
                    this.onClose();
                })
            } catch (error){
                console.log(`err: ${error}`);
            }
        } else {
            try {
                this._projectService.patch(this.data._id, {
                    ...this.editProjectForm.value,
                    codes: this.codes,
                    accounts: this.jiraAccountIds.value,
                }).subscribe(data => {

                    //TODO if admin2
                    this.onClose();
                })
            } catch (error){
                console.log(`err: ${error}`);
            }
        }
    }
    removeProject(){
        this._projectService.delete(this.data._id).subscribe(response => {
            if(response && response.success){
                this.onClose();
            }
        })
    }
}
