import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {of} from 'rxjs';
import {CommonUtilitiesService} from "./common-utilities.service";
import {AuthService} from "../auth/auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EngagementService
{
    currentEngagement;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,

        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}


    availableRoles(){
        return {
            developer: 'Developer',
            manager: 'Manager',
            admin: 'Admin',
            client: 'Client',
            'mtt-dev': 'MTT Developer',
        }
    }

    getEngagement(id: any, params: object) {
        if (!id) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/engagements/${id}`
        );
    }

    getEngagements(params: object) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/engagements/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    getEngagementsWithinMonth(date: string) {
        const params = new HttpParams().set('date', date);
        return this._httpClient.get<any>(`${environment.apiUrl}/engagements/within-month`, { params });
    }


    createEngagement(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/engagements/`, params);
    }

    updateEngagement(engagementId: string, params: object) {
        if (!engagementId) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/engagements/${engagementId}`, params);
    }

    patch(engagementId: string, params: object)
    {
        if (!engagementId) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }
        return this._httpClient.patch<any>(`${environment.apiUrl}/engagements/${engagementId}`, params);
    }

    deleteEngagement(engagementId: string) {
        if (!engagementId) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/engagements/${engagementId}`);
    }
}
