import { Route } from '@angular/router';
import {WeeklyReportResolvers} from "./weekly-report.resolvers";
import {WeeklyReportComponent} from "./weekly-report.component";

export const weeklyDashboardRoutes: Route[] = [
    {
        path     : '',
        component: WeeklyReportComponent,
        resolve  : {
            data: WeeklyReportResolvers
        }
    }
];
