import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from "../../../environments/environment";
import { CommonUtilitiesService } from "./common-utilities.service";

@Injectable({
    providedIn: 'root'
})
export class ClientStatusAssertionService {
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}

    /**
     * Get client status assertion by ID
     * @param id The ID of the client status assertion
     * @returns Observable with the client status assertion
     */
    getClientStatusAssertion(id: string): Observable<any> {
        if (!id) {
            return of({
                success: false,
                error: 'client status assertion id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/client-status-assertions/${id}`
        );
    }

    /**
     * Create a new client status assertion
     * @param params The client status assertion data
     * @returns Observable with the created client status assertion
     */
    createClientStatusAssertion(params: object): Observable<any> {
        return this._httpClient.post<any>(`${environment.apiUrl}/client-status-assertions/`, params);
    }

    /**
     * Get the most recent assertion of each type for a given engagement
     * @param engagementId The ID of the engagement
     * @returns Observable with the latest client status assertions
     */
    getLatestAssertionsByEngagement(engagementId: string): Observable<any> {
        if (!engagementId) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/client-status-assertions/engagements/${engagementId}/latest`
        );
    }

    /**
     * Get status type enum values
     * These should match the backend enum values
     */
    getStatusTypes() {
        return {
            OVERALL: 'overall',
            BUDGET: 'budget',
            SCHEDULE: 'schedule',
            RISK: 'risk'
        };
    }

    /**
     * Get color-coded status enum values
     * These should match the backend enum values
     */
    getColorCodedStatuses() {
        return {
            GREEN: 'green',
            YELLOW: 'yellow',
            RED: 'red'
        };
    }
}
