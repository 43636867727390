import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApexOptions} from "ng-apexcharts";
import {Subject} from "rxjs";
import {ProjectService} from "../../../core/_services/project.service";
import {TimeEntryService} from "../../../core/_services/time-entry.service";
import {UserService} from "../../../core/_services/user.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import {CommonUtilitiesService} from "../../../core/_services/common-utilities.service";

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.scss']
})
export class WeeklyReportComponent implements OnInit, OnDestroy
{
    weekIndex = 0;
    colors = {
        zero: {
            color: "#ffffff",
            backgroundColor: '#F49097'
        },
        less: {
            color: "#ffffff",
            backgroundColor: '#F5E960',
        },
        norm: {
            backgroundColor: '#F2F5FF',
        },
        good: {
            color: "#ffffff",
            backgroundColor: '#55D6C2',
        },
    }

    alphabeticalAscending: Boolean = true;

    holidays = [];
    weekHolidays = [];
    daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    userFilterText = "";
    availableMonths;

    projects;
    users = [];
    userDict;
    projectDict;
    timeEntries;
    totalHours;
    totalEarnings;
    projectedRatio;

    goBackWeeks = Array(52).fill(0);
    selectedWeek = 'Current Week';

    // template variables below
    data: any;

    /**
     * Constructor
     */
    constructor(
        private _projectService: ProjectService,
        private _timeEntryService: TimeEntryService,
        private _userService: UserService,
        private _router: Router,
        private _commonUtilitiesService: CommonUtilitiesService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.holidays = this._commonUtilitiesService.getHolidays(moment().format('YYYY'));
        this.loadWorklogWeek();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {

    }

    roundToTwoDecimal(number){
        if(number % 1 > 0){
            return number.toFixed(2);
        } else {
            return number.toFixed(0);
        }
    }

    sumOfHoursObj(hoursObj){
        let total = 0;
        Object.keys(hoursObj).forEach(proj => {
            total += hoursObj[proj];
        })
        return this.roundToTwoDecimal(total);
    }

    getProjectKeyArr(hoursObj){
        return Object.keys(hoursObj);
    }

    getWeekStartUI(index?){
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - startDate.getDay());
        if(index){
            startDate.setDate(startDate.getDate() - (index *7));
        }
        return moment(startDate).format('MMMM Do YYYY');
    }
    getWeekStartQuery(index?){
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - startDate.getDay());
        if(index){
            startDate.setDate(startDate.getDate() - (index *7));
        }
        return moment(startDate);
    }

    filteredUsers(){
        if (this.alphabeticalAscending){
            this.users.sort(function (a, b) {
                if (a.nameLast < b.nameLast) {
                    return -1
                }
                if (a.nameLast > b.nameLast) {
                    return 1;
                }
                if (a.nameFirst < b.nameFirst){
                    return -1;
                }
                if (a.nameFirst > b.nameFirst){
                    return 1;
                }
                return 0;
            });
        } else {
            this.users.sort(function (a, b) {
                if (a.nameLast < b.nameLast) {
                    return 1;
                }
                if (a.nameLast > b.nameLast) {
                    return -1;
                }
                if (a.nameFirst < b.nameFirst){
                    return 1;
                }
                if (a.nameFirst > b.nameFirst){
                    return -1;
                }
                return 0;
            });
        }


        return this.users.map(user => {
            if (this.userDict[user._id].name.toLowerCase().includes(this.userFilterText.toLowerCase())){
                return user;
            }
        })
    }

    loadWorklog = () => {
        const startDate = this.getWeekStartQuery(this.weekIndex);
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(startDate).add(7, 'd').format('YYYY-MM-DD');

        try {
            this.userDict={};
            this.projectDict={};

            this._userService.getUsers({}).subscribe(users => {
                this.users = users;
                for (const user of this.users) {
                    this.userDict[user._id] = {
                        name: `${user.nameFirst} ${user.nameLast}`,
                        hours: [{}, {}, {}, {}, {}, {}, {}],
                        totalHours: 0,
                        associatedProjects: [],
                        associatedProjectsHours: [],
                        detailActive: false,
                    }
                }
                this._projectService.getProjects({}).subscribe(projects => {
                    this.projects = projects;
                    this.projects.map(project => {
                        this.projectDict[project._id] = {
                            name: `${project.name}`,
                        };
                    })
                    this._timeEntryService.getTimeEntryRange(start, end).subscribe(timeEntries => {
                        this.timeEntries = timeEntries;
                        this.timeEntries.forEach(timeEntry => {
                            const projectName = this.projectDict[timeEntry.project].name;
                            const dayOfWeek = moment.utc(timeEntry.timestamp).subtract(11, 'h').day(); //timezone

                            if (!this.userDict[timeEntry.user]){
                                console.log(timeEntry.user + ' does not exist');
                                return;
                            }

                            if(!this.userDict[timeEntry.user].associatedProjects.includes(projectName)){
                                this.userDict[timeEntry.user].associatedProjects.push(projectName);
                                this.userDict[timeEntry.user].associatedProjectsHours.push(timeEntry.timeSpent);
                            } else {
                                this.userDict[timeEntry.user].associatedProjectsHours[this.userDict[timeEntry.user].associatedProjects.indexOf(projectName)] += timeEntry.timeSpent;
                            }

                            this.userDict[timeEntry.user].totalHours += timeEntry.timeSpent;
                            if(this.userDict[timeEntry.user].hours[dayOfWeek][projectName]){
                                this.userDict[timeEntry.user].hours[dayOfWeek][projectName] += timeEntry.timeSpent;
                            } else {
                                this.userDict[timeEntry.user].hours[dayOfWeek][projectName] = timeEntry.timeSpent;
                            }
                        });
                    });
                });
            })
        }catch(error){
            console.log('err', error);
        }
    }

    hourFlagger(user, dayOfWeek){
        if (dayOfWeek == 0 || dayOfWeek == 6){
            return this.sumOfHoursObj(this.userDict[user._id].hours[dayOfWeek]) > 0 ?
                this.colors.good : this.colors.norm;
        }
        if (this.holidays.length > 0){
            if(this.weekHolidays[dayOfWeek]){
                return this.colors.norm;
            }
            //target.isBetween(start, finish, 'days', '[)') // left inclusive
        }
        return this.sumOfHoursObj(this.userDict[user._id].hours[dayOfWeek]) >= 8 ?
            this.colors.good : this.sumOfHoursObj(this.userDict[user._id].hours[dayOfWeek]) > 0 ? this.colors.less : this.colors.zero
    }

    loadWorklogWeek(index?){
        this.weekIndex = index ? index : this.weekIndex;
        this.userFilterText = "";
        this.selectedWeek = `Week of ${this.getWeekStartUI(this.weekIndex)}`;
        const startDate = this.getWeekStartQuery(this.weekIndex).subtract(1, "days").utc();

        const weekDays = [];
        for(let i = 0; i <= 6; i++){
            weekDays.push(startDate.add(1, 'days').format('YYYY-MM-DD'));
        }
        this.weekHolidays = weekDays.map(weekDay => {
            if (this.holidays.includes(weekDay)){
                return 1;
            } else {
                return 0;
            }
        });

        this.loadWorklog();
    }

    // Template below

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fix the SVG fill references. This fix must be applied to all ApexCharts
     * charts in order to fix 'black color on gradient fills on certain browsers'
     * issue caused by the '<base>' tag.
     *
     * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
     *
     * @param element
     * @private
     */
    private _fixSvgFill(element: Element): void
    {
        // Current URL
        const currentURL = this._router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _loadPieCharts = async() => {


    }
}

