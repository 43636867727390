import { Route } from '@angular/router';
import {ManagementResolvers} from "./management.resolvers";
import {ManagementComponent} from "./management.component";

export const dailyReportRoutes: Route[] = [
    {
        path     : '',
        component: ManagementComponent,
        resolve  : {
            data: ManagementResolvers
        }
    }
];
