<h2 mat-dialog-title>{{ dialogTitle }}</h2>

<form [formGroup]="statusForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex flex-col gap-4">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" required>
          <mat-option value="green">Green</mat-option>
          <mat-option value="yellow">Yellow</mat-option>
          <mat-option value="red">Red</mat-option>
        </mat-select>
        <mat-error *ngIf="statusForm.get('status').hasError('required')">
          Status is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Comments</mat-label>
        <textarea matInput formControlName="comments" rows="4" required></textarea>
        <mat-error *ngIf="statusForm.get('comments').hasError('required')">
          Comments are required
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="statusForm.invalid">Submit</button>
  </mat-dialog-actions>
</form>
