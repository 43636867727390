import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {of} from 'rxjs';
import {CommonUtilitiesService} from "./common-utilities.service";
import {AuthService} from "../auth/auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    currentUser;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,

        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}


    availableRoles(){
        return {
            developer: 'Developer',
            manager: 'Manager',
            admin: 'Admin',
            client: 'Client',
            'mtt-dev': 'MTT Developer',
        }
    }

    getUser(id: any, params: object) {
        if (!id) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/users/${id}`
        );
    }

    getUsers(params: object) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/users/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    createUser(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/users/`, params);
    }

    updateUser(userId: string, params: object) {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/users/${userId}`, params);
    }

    patch(userId: string, params: object)
    {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.patch<any>(`${environment.apiUrl}/users/${userId}`, params);
    }

    deleteUser(userId: string) {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/users/${userId}`);
    }
}
