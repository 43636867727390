import { Route } from '@angular/router';
import {ProjectReportResolvers} from "./project-report.resolvers";
import {ProjectReportComponent} from "./project-report.component";

export const projectReportRoutes: Route[] = [
    {
        path     : '',
        component: ProjectReportComponent,
        resolve  : {
            data: ProjectReportResolvers
        }
    }
];
