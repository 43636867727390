import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, of, tap} from 'rxjs';
import {environment} from "../../../environments/environment";
import {CommonUtilitiesService} from "./common-utilities.service";

@Injectable({
    providedIn: 'root'
})
export class ProjectService
{
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}

    getProjects(params: object) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/projects/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    getProject(id: any, params: object) {
        if (!id) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/projects/${id + this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    createProject(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/projects/`, params);
    }

    updateProject(projectId: string, params: object) {
        if (!projectId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/projects/${projectId}`, params);
    }

    patch(projectId: string, params: object)
    {
        if (!projectId) {
            return of({
                success: false,
                error: 'project id not provided to service function'
            });
        }
        return this._httpClient.patch<any>(`${environment.apiUrl}/projects/${projectId}`, params);
    }

    delete(projectId: string)
    {
        if (!projectId) {
            return of({
                success: false,
                error: 'project id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/projects/${projectId}`);
    }

    // Template below
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any>
    {
        return this._data.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any>
    {
        return this._httpClient.get('api/dashboards/project').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }
}
