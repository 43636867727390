import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonUtilitiesService {

    constructor() {}

    formatViewTime(timeInSeconds: number): string {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        return `${minutes}m${seconds}s`;
    }

    generateURLParameters(params: object): string {
        let urlParams = '';
        let count = 0;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlParams += (count === 0 ? '?' : '&') + key + '=' + params[key];
                ++count;
            }
        }
        return urlParams;
    }

    getHolidays(year){
        const holidays = {
            2023: [
                '2023-01-02',
                '2023-01-16',
                '2023-02-20',
                '2023-05-29',
                '2023-06-19',
                '2023-07-04',
                '2023-09-04',
                '2023-10-09',
                '2023-11-10',
                '2023-11-23',
                '2023-12-25',
            ],
            2024: [
                '2024-01-01',
                '2024-01-15',
                '2024-05-27',
                '2024-06-19',
                '2024-09-02',
                '2024-10-14',
                '2024-11-11',
                '2024-11-28',
            ]
        }

        if (holidays[year] && holidays[year] !== undefined){
            return holidays[year];
        }
        return [];
    }
}
