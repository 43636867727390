<h2 mat-dialog-title class="dialog-title" style="font-weight: bold">Confirm Sync Month</h2>
<div mat-dialog-content class="dialog-content">
    <p>Are you sure you want to sync time-logs for the month?</p>
    <p>Time logs from <strong>{{ formatDate(data.startOfMonth)}}</strong> to <strong>{{formatDate(data.endOfMonth)}}</strong> will be synced.</p>
    <p style="font-size: 10px;"><i>Please note that this request deletes existing time-logs based on the requested period and re-syncs time-log data regardless of current block-off date.</i></p>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onClose(true)" class="yes-button">Yes</button>
    <button mat-button (click)="dialogRef.close()" class="no-button">No</button>
</div>
