<h2 mat-dialog-title>Generate New Status Report</h2>

<form [formGroup]="statusReportForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex flex-col gap-4">
      <!-- Date Range Section -->
      <div class="text-lg font-medium mt-2">Reporting Period</div>
      
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="reportingPeriodStartDate" placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="statusReportForm.get('reportingPeriodStartDate')?.hasError('required')">Start date is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="reportingPeriodEndDate" placeholder="Choose an end date">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="statusReportForm.get('reportingPeriodEndDate')?.hasError('required')">End date is required</mat-error>
      </mat-form-field>

      <mat-error *ngIf="statusReportForm.hasError('dateRangeInvalid')">
        End date cannot be before start date
      </mat-error>

      <!-- Status Section -->
      <div class="text-lg font-medium mt-2">Status Values</div>
      
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Overall Status</mat-label>
        <mat-select formControlName="overallStatusValue" required>
          <mat-option value="green">Green</mat-option>
          <mat-option value="yellow">Yellow</mat-option>
          <mat-option value="red">Red</mat-option>
        </mat-select>
        <mat-error *ngIf="statusReportForm.get('overallStatusValue')?.hasError('required')">
          Overall status is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Budget Status</mat-label>
        <mat-select formControlName="budgetStatusValue" required>
          <mat-option value="green">Green</mat-option>
          <mat-option value="yellow">Yellow</mat-option>
          <mat-option value="red">Red</mat-option>
        </mat-select>
        <mat-error *ngIf="statusReportForm.get('budgetStatusValue')?.hasError('required')">
          Budget status is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Schedule Status</mat-label>
        <mat-select formControlName="scheduleStatusValue" required>
          <mat-option value="green">Green</mat-option>
          <mat-option value="yellow">Yellow</mat-option>
          <mat-option value="red">Red</mat-option>
        </mat-select>
        <mat-error *ngIf="statusReportForm.get('scheduleStatusValue')?.hasError('required')">
          Schedule status is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Risk Status</mat-label>
        <mat-select formControlName="riskStatusValue" required>
          <mat-option value="green">Green</mat-option>
          <mat-option value="yellow">Yellow</mat-option>
          <mat-option value="red">Red</mat-option>
        </mat-select>
        <mat-error *ngIf="statusReportForm.get('riskStatusValue')?.hasError('required')">
          Risk status is required
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="statusReportForm.invalid || isSubmitting">
      <span *ngIf="isSubmitting">Generating...</span>
      <span *ngIf="!isSubmitting">Generate Report</span>
    </button>
  </mat-dialog-actions>
</form>
