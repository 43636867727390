import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, of, tap} from 'rxjs';
import {environment} from "../../../environments/environment";
import {CommonUtilitiesService} from "./common-utilities.service";

@Injectable({
    providedIn: 'root'
})
export class AccountService
{
   constructor(
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}

    getAccounts(params: object) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/accounts/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    getAccount(id: any, params: object) {
        if (!id) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/accounts/${id + this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    createAccount(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/accounts/`, params);
    }

    updateAccount(accountId: string, params: object) {
        if (!accountId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/accounts/${accountId}`, params);
    }

    patch(accountId: string, params: object)
    {
        if (!accountId) {
            return of({
                success: false,
                error: 'account id not provided to service function'
            });
        }
        return this._httpClient.patch<any>(`${environment.apiUrl}/accounts/${accountId}`, params);
    }

    delete(accountId: string)
    {
        if (!accountId) {
            return of({
                success: false,
                error: 'account id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/accounts/${accountId}`);
    }
}
