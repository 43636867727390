import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApexOptions} from "ng-apexcharts";
import {Subject} from "rxjs";
import {ProjectService} from "../../../core/_services/project.service";
import {TimeEntryService} from "../../../core/_services/time-entry.service";
import {UserService} from "../../../core/_services/user.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import {CommonUtilitiesService} from "../../../core/_services/common-utilities.service";

@Component({
  selector: 'app-weekly-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit, OnDestroy
{
    dayIndex = 0;
    dayEventIndex = 0;

    projects;
    users;
    userDict;
    projectDict;
    timeEntries;
    totalHours;
    totalEarnings;
    projectedRatio;

    goBackDays = Array(52).fill(0);
    selectedDay: string = 'Today';

    chartsLoaded = false;

    earningByDate: ApexOptions = {};


    // template variables below
    data: any;

    chartProjectEarningDistribution: ApexOptions;
    chartProjectTimeDistribution: ApexOptions;
    chartUserTimeContribution: ApexOptions;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _projectService: ProjectService,
        private _timeEntryService: TimeEntryService,
        private _userService: UserService,
        private _commonUtilitiesService: CommonUtilitiesService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loadReportDay(this.dayIndex);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getDayStartUI(index?){
        let startDate;
        if(index){
            startDate = moment().add(-index, 'd').startOf('day');
        } else {
            startDate = moment().startOf('day');
        }
        return startDate.format('MMM Do YYYY');
    }
    getDayStartQuery(index?){
        let startDate;
        if(index){
            startDate = moment().add(-index, 'd').startOf('day');
        } else {
            startDate = moment().startOf('day');
        }
        return startDate.format('YYYY-MM-DD');
    }

    loadTimelogs = async () => {
        const startDate = this.getDayStartQuery(this.dayIndex + this.dayEventIndex);
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(startDate).add(1, 'd').format('YYYY-MM-DD');
        console.log(start, end);
        try {
            this.totalHours = 0;
            this.totalEarnings = 0;
            this.userDict={};
            this.projectDict={};

            this._userService.getUsers({}).subscribe(users => {
                this.users = users;
                this.users.forEach(user => {
                    this.userDict[user._id] = {
                        name: `${user.nameFirst} ${user.nameLast}`,
                        hours: 0,
                        billingType: user.billingType == 'senior' ? 'seniorBillingRate' : user.billingType == 'mid' ? 'midBillingRate' : 'juniorBillingRate',
                    }
                });
                this._projectService.getProjects({}).subscribe(projects => {
                    this.projects = projects;
                    this.projects.map(project => {
                        this.projectDict[project._id] = {
                            name: project.name ? `${project.name}`: "",
                            cost: 0,
                            hours: 0,
                            juniorBillingRate: project.juniorBillingRate,
                            midBillingRate: project.midBillingRate,
                            seniorBillingRate: project.seniorBillingRate,
                        };
                    })
                    this._timeEntryService.getTimeEntryRange(start, end).subscribe(timeEntries => {
                        this.timeEntries = timeEntries;
                        this.timeEntries.forEach(timeEntry => {
                            // timeEntry display name fields
                            timeEntry.userName = this.userDict[timeEntry.user] ? this.userDict[timeEntry.user].name : "unknown";
                            timeEntry.projectName = this.projectDict[timeEntry.project] ? this.projectDict[timeEntry.project].name : "";

                            // userdata due to issue with unmatched user, in if block
                            let timeEntryCost = 0;
                            if (this.userDict[timeEntry.user]){
                                // hour
                                this.userDict[timeEntry.user].hours += timeEntry.timeSpent;
                                // cost
                                timeEntryCost = this.projectDict[timeEntry.project][this.userDict[timeEntry.user].billingType] * timeEntry.timeSpent;
                            }
                            //hours
                            if (this.projectDict[timeEntry.project]){
                                this.projectDict[timeEntry.project].hours += timeEntry.timeSpent;
                                this.projectDict[timeEntry.project].cost += timeEntryCost;
                            }
                            this.totalHours += timeEntry.timeSpent;
                            this.totalEarnings += timeEntryCost;
                        });
                        console.log(this.timeEntries);

                    });
                });
            })
        }catch(error){
            console.log('err', error);
        }

    }

    loadReportDay(index){
        this.dayIndex = index;
        this.selectedDay = `${this.getDayStartUI(this.dayIndex)}`;
        this.loadTimelogs();
    }

    dayTabSelect($event){
        this.dayEventIndex = $event.index;
        this.loadTimelogs();
    }
    formatTime(time){
        return moment(time).format('M/DD/YYYY h:mm a');
    }

    // Template below

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fix the SVG fill references. This fix must be applied to all ApexCharts
     * charts in order to fix 'black color on gradient fills on certain browsers'
     * issue caused by the '<base>' tag.
     *
     * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
     *
     * @param element
     * @private
     */
    private _fixSvgFill(element: Element): void
    {
        // Current URL
        const currentURL = this._router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
}

