import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusReportService } from '../../../core/_services/status-report.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientStatusAssertionService } from '../../../core/_services/client-status-assertion.service';
import { TimeOffService } from '../../../core/_services/time-off.service';

@Component({
  selector: 'app-status-report-dialog',
  templateUrl: './status-report-dialog.component.html',
  styleUrls: ['./status-report-dialog.component.scss']
})
export class StatusReportDialogComponent implements OnInit {
  statusReportForm: FormGroup;
  isSubmitting = false;
  engagementId: string;
  statusOptions: any;
  latestStatusAssertions: any = {};
  latestStatusAssertionIds: any = {};
  timeOffs: any[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<StatusReportDialogComponent>,
    private _statusReportService: StatusReportService,
    private _clientStatusAssertionService: ClientStatusAssertionService,
    private _timeOffService: TimeOffService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { engagementId: string }
  ) {
    this.engagementId = data.engagementId;
    this.statusOptions = this._statusReportService.getColorCodedStatuses();
  }

  ngOnInit(): void {
    this.getLatestStatusAssertions();
    this.getEngagementTimeOffs();
    this.createForm();
  }

  getLatestStatusAssertions(): void {
    this._clientStatusAssertionService.getLatestAssertionsByEngagement(this.engagementId).subscribe(
      (assertions) => {
        // Map assertions by their type
        assertions.forEach((assertion: any) => {
          this.latestStatusAssertions[assertion.type] = assertion.status;
          this.latestStatusAssertionIds[assertion.type] = assertion._id;
        });
        
        // Update form with latest values
        this.updateFormWithLatestStatuses();
      },
      (error) => {
        console.error('Error fetching latest status assertions:', error);
      }
    );
  }

  getEngagementTimeOffs(): void {
    this._timeOffService.getEngagementTimeOffs(this.engagementId).subscribe(
      (timeOffs) => {
        this.timeOffs = timeOffs;
      },
      (error) => {
        console.error('Error fetching time offs:', error);
      }
    );
  }

  createForm(): void {
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);

    this.statusReportForm = this._formBuilder.group({
      reportingPeriodStartDate: [oneWeekAgo, Validators.required],
      reportingPeriodEndDate: [today, Validators.required],
      overallStatusValue: ['green', Validators.required],
      budgetStatusValue: ['green', Validators.required],
      scheduleStatusValue: ['green', Validators.required],
      riskStatusValue: ['green', Validators.required],
    }, { validators: this.dateRangeValidator });
  }

  updateFormWithLatestStatuses(): void {
    const statusTypes = this._clientStatusAssertionService.getStatusTypes();
    
    // Update each status control with the latest assertion status if available
    if (this.latestStatusAssertions[statusTypes.OVERALL]) {
      this.statusReportForm.get('overallStatusValue').setValue(this.latestStatusAssertions[statusTypes.OVERALL]);
    }
    
    if (this.latestStatusAssertions[statusTypes.BUDGET]) {
      this.statusReportForm.get('budgetStatusValue').setValue(this.latestStatusAssertions[statusTypes.BUDGET]);
    }
    
    if (this.latestStatusAssertions[statusTypes.SCHEDULE]) {
      this.statusReportForm.get('scheduleStatusValue').setValue(this.latestStatusAssertions[statusTypes.SCHEDULE]);
    }
    
    if (this.latestStatusAssertions[statusTypes.RISK]) {
      this.statusReportForm.get('riskStatusValue').setValue(this.latestStatusAssertions[statusTypes.RISK]);
    }
  }

  dateRangeValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const startDate = formGroup.get('reportingPeriodStartDate')?.value;
    const endDate = formGroup.get('reportingPeriodEndDate')?.value;

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      return { 'dateRangeInvalid': true };
    }
    
    return null;
  }

  onSubmit(): void {
    if (this.statusReportForm.invalid) {
      return;
    }

    this.isSubmitting = true;

    const statusTypes = this._clientStatusAssertionService.getStatusTypes();
    
    const timeOffIds = this.timeOffs.map(timeOff => timeOff._id);

    // Get form values
    const formValues = this.statusReportForm.value;
    
    // Set start date to beginning of day and end date to end of day in local timezone
    if (formValues.reportingPeriodStartDate) {
      const startDate = new Date(formValues.reportingPeriodStartDate);
      startDate.setHours(0, 0, 0, 0);
      formValues.reportingPeriodStartDate = startDate;
    }
    
    if (formValues.reportingPeriodEndDate) {
      const endDate = new Date(formValues.reportingPeriodEndDate);
      endDate.setHours(23, 59, 59, 999);
      formValues.reportingPeriodEndDate = endDate;
    }

    const statusReportData = {
      ...formValues,
      engagement: this.engagementId,
      overallStatus: this.latestStatusAssertionIds[statusTypes.OVERALL] || null,
      budgetStatus: this.latestStatusAssertionIds[statusTypes.BUDGET] || null,
      scheduleStatus: this.latestStatusAssertionIds[statusTypes.SCHEDULE] || null,
      riskStatus: this.latestStatusAssertionIds[statusTypes.RISK] || null,
      timeOffs: timeOffIds
    };

    this._statusReportService.createStatusReport(statusReportData).subscribe(
      (response) => {
        this.isSubmitting = false;
        this._snackBar.open('Status report generated successfully', 'Close', {
          duration: 3000
        });
        this._dialogRef.close(true);
      },
      (error) => {
        this.isSubmitting = false;
        this._snackBar.open(error.error?.error || 'Error generating status report', 'Close', {
          duration: 3000
        });
        console.error('Error generating status report:', error);
      }
    );
  }

  cancel(): void {
    this._dialogRef.close();
  }
}
