<div class="flex flex-row justify-between items-center">
    <div class=" text-3xl font-semibold tracking-tight leading-snug truncate">
        {{view === "new" ? "New Project" : "Edit Project"}}
    </div>
    <button
        *ngIf="view !== 'new'"
        class="fuse-mat-button-small mt-0.5 justify-center items-center"
        mat-flat-button
        [color]="'accent'"
        (click)="removeProject()">
        <mat-icon
            class="text-white"
            [svgIcon]="'heroicons_outline:trash'"></mat-icon>
    </button>
</div>

<div mat-dialog-content *ngIf="data" class="mt-2">
    <form
        class="mt-2"
        [formGroup]="editProjectForm"
        #editProjectNgForm="ngForm">
        <!-- Name field -->
        <div style="display:flex; justify-content: space-between;">
            <mat-form-field class="w-full">
                <mat-label>Project Name</mat-label>
                <input
                    id="name"
                    matInput
                    [formControlName]="'name'">
                <mat-error *ngIf="editProjectForm.get('name').hasError('required')">
                    Project name is required
                </mat-error>
            </mat-form-field>
        </div>
        <!-- Email field -->
        <mat-form-field class="w-full" style="">
            <mat-label>Project Description</mat-label>
            <input
                id="description"
                matInput
                [formControlName]="'description'">
            <!--                <mat-error *ngIf="editProfileForm.get('description').hasError('required')">-->
            <!--                    Last name is required-->
            <!--                </mat-error>-->
        </mat-form-field>
        <!-- Email field -->
        <mat-form-field class="w-full">
            <mat-label>Estimated Cost</mat-label>
            <input
                id="estimatedCost"
                matInput
                [formControlName]="'estimatedCost'">
<!--            <mat-error *ngIf="editProfileForm.get('estimatedCost').hasError('required')">-->
<!--                estimatedCost is required-->
<!--            </mat-error>-->
<!--            <mat-error *ngIf="editProfileForm.get('email').hasError('email')">-->
<!--                Please enter a valid email address-->
<!--            </mat-error>-->
        </mat-form-field>

        <!-- Bill Type, Hourly Cost-->
        <div style="display:flex; justify-content: space-between;">
            <mat-form-field class="" style="width: 50%">
                <mat-label>Junior Dev Rate</mat-label>
                <input
                    id="juniorBillingRate"
                    matInput
                    [formControlName]="'juniorBillingRate'">
                <mat-error *ngIf="editProjectForm.get('juniorBillingRate').hasError('required')">
                    juniorBillingRate is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="" style="width: 50%">
                <mat-label>Mid Dev Rate</mat-label>
                <input
                    id="midBillingRate"
                    matInput
                    [formControlName]="'midBillingRate'">
                <mat-error *ngIf="editProjectForm.get('midBillingRate').hasError('required')">
                    midBillingRate is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="" style="width: 50%">
                <mat-label>Senior Dev Rate</mat-label>
                <input
                    id="seniorBillingRate"
                    matInput
                    [formControlName]="'seniorBillingRate'">
                <mat-error *ngIf="editProjectForm.get('seniorBillingRate').hasError('required')">
                    seniorBillingRate is required
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex justify-between items-center mb-3">
            <div style="color: #1e293b; font-weight: 500;">Jira Project Ids</div>
            <button
                class="mt-0.5"
                mat-flat-button
                [color]="'accent'"
                (click)="addJiraAccount()">
                Add JIRA Account
                <!--                <mat-icon class="add-course-btn">add</mat-icon>-->
            </button>
        </div>

        <div class="flex flex-row" style="align-items: center"
             *ngFor="let jiraAccountId of editProjectForm.get('jiraProjectIds').controls; let i=index">
            <div style="width: 3%"></div>
            <mat-form-field style="width: 45%;">
                <mat-label>Account</mat-label>
                <div class="flex flex-row justify-items-start">
                    <mat-select [formControl]="jiraAccountId.get('account')">
                        <mat-option *ngFor="let option of accountOptions" [value]="option._id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </div>
                <mat-error *ngIf="jiraAccountId.get('account').hasError('required')">
                    Account Selection is Required
                </mat-error>
            </mat-form-field>
            <mat-form-field style="width: 45%; margin-left: 5px;">
                <mat-label>JIRA Project ID</mat-label>
                <input
                    matInput
                    [formControl]="jiraAccountId.get('projectId')"
                    placeholder="Jira Project Id"
                />
            </mat-form-field>
            <button mat-icon-button (click)="removeJiraAccount(i)" matTooltip="Remove" [color]="'warn'">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
        <!-- Jira Username, AccountId-->
<!--        <div style="display:flex; justify-content: space-between;">-->
<!--            <mat-form-field class="" style="width: 50%">-->
<!--                <mat-label>JIRA Project ID</mat-label>-->
<!--                <input-->
<!--                    id="jiraProjectId"-->
<!--                    matInput-->
<!--                    [formControlName]="'jiraProjectId'">-->
<!--&lt;!&ndash;                <mat-error *ngIf="editProfileForm.get('jiraProjectId').hasError('required')">&ndash;&gt;-->
<!--&lt;!&ndash;                    jiraProjectId is required&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-error>&ndash;&gt;-->
<!--            </mat-form-field>-->
<!--&lt;!&ndash;            <mat-form-field class="" style="width: 50%">&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-label>jiraAccountId</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                <input&ndash;&gt;-->
<!--&lt;!&ndash;                    id="jiraProjectId"&ndash;&gt;-->
<!--&lt;!&ndash;                    matInput&ndash;&gt;-->
<!--&lt;!&ndash;                    [formControlName]="'jiraAccountId'">&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-error *ngIf="editProfileForm.get('jiraAccountId').hasError('required')">&ndash;&gt;-->
<!--&lt;!&ndash;                    jiraAccountId is required&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-error>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-form-field>&ndash;&gt;-->
<!--        </div>-->

        <!-- Jira Username, AccountId-->
        <div style="display:flex; justify-content: space-around;">
            <mat-form-field class="" style="width: 80%">
                <mat-label>Add New Code</mat-label>
                <input
                    id="code"
                    matInput
                    [formControlName]="'code'">
                <!--                <mat-error *ngIf="editProfileForm.get('jiraProjectId').hasError('required')">-->
                <!--                    jiraProjectId is required-->
                <!--                </mat-error>-->
            </mat-form-field>
            <button mat-flat-button
                    (click)="addCode()"
                    [color]="'primary'"
                    style="margin-top: 26px">
<!--                <mat-icon-->
<!--                    class="icon-size-5"-->
<!--                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>-->
<!--                <span class="ml-2">Add</span>-->
                Add
            </button>
            <!--            <mat-form-field class="" style="width: 50%">-->
            <!--                <mat-label>jiraAccountId</mat-label>-->
            <!--                <input-->
            <!--                    id="jiraProjectId"-->
            <!--                    matInput-->
            <!--                    [formControlName]="'jiraAccountId'">-->
            <!--                <mat-error *ngIf="editProfileForm.get('jiraAccountId').hasError('required')">-->
            <!--                    jiraAccountId is required-->
            <!--                </mat-error>-->
            <!--            </mat-form-field>-->

        </div>
        <div class="flex flex-row flex-auto overflow-hidden p-6" *ngIf="codes.length > 0">
            <div class="-my-3 divide-y">
                <ng-container>
                    <div class="grid grid-cols-6 py-3">
                        <div class="col-span-2 font-medium text-left text-black">Codes</div>
                        <div class="col-span-2 font-medium text-left text-black">Remove</div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let code of codes; let i = index">
                    <div class="grid grid-cols-6 py-3">
                        <div class="col-span-2 font-medium text-gray-900 text-left">{{code}}</div>
                        <div class="col-span-2 font-medium text-red-900 text-left">
                            <button mat-flat-button
                                    (click)="removeCode(code)"
                                    [color]="'primary'">
                                <!--                <mat-icon-->
                                <!--                    class="icon-size-5"-->
                                <!--                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>-->
                                <!--                <span class="ml-2">Add</span>-->
                                Remove
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- SlackId field -->
<!--        <mat-form-field class="w-full">-->
<!--            <mat-label>Slack Id</mat-label>-->
<!--            <input-->
<!--                id="slackId"-->
<!--                matInput-->
<!--                [formControlName]="'slackId'">-->
<!--            <mat-error *ngIf="editProfileForm.get('slackId').hasError('required')">-->
<!--                slackId is required-->
<!--            </mat-error>-->
<!--        </mat-form-field>-->

        <!-- Submit button -->
        <button
            class="fuse-mat-button-large w-full mt-6"
            mat-flat-button
            [color]="'primary'"
            [disabled]="editProjectForm.disabled"
            (click)="updateProject()">
                    <span>
                        {{view === "new" ? "Create" : "Update"}} Project
                    </span>
            <mat-progress-spinner
                *ngIf="editProjectForm.disabled"
                [diameter]="24"
                [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
        <button
            class="fuse-mat-button-large w-full mt-0.5"
            mat-flat-button
            [color]="'accent'"
            (click)="onClose()">
                    <span>
                        Cancel
                    </span>
        </button>
    </form>
</div>
<!--<div mat-dialog-actions>
    <button mat-button (click)="onClose()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>-->
