import { Route } from '@angular/router';
import {MonthlyReportComponent} from "./monthly-report.component";
import {MonthlyReportResolvers} from "./monthly-report.resolvers";

export const monthlyReportRoutes: Route[] = [
    {
        path     : '',
        component: MonthlyReportComponent,
        resolve  : {
            data: MonthlyReportResolvers
        }
    }
];
