import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from "../../../environments/environment";
import { CommonUtilitiesService } from "./common-utilities.service";

@Injectable({
    providedIn: 'root'
})
export class StatusReportService {
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}

    /**
     * Create a new status report
     * @param params The status report data
     * @returns Observable with the created status report
     */
    createStatusReport(params: object): Observable<any> {
        return this._httpClient.post<any>(`${environment.apiUrl}/status-reports/`, params);
    }

    /**
     * Get all status reports for a specific engagement with optional pagination and date filtering
     * @param engagementId The ID of the engagement
     * @param params Optional parameters for pagination and date filtering
     * @returns Observable with the status reports and pagination info
     */
    getEngagementStatusReports(engagementId: string, params?: {
        page?: number,
        limit?: number,
        startDate?: Date,
        endDate?: Date
    }): Observable<any> {
        if (!engagementId) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }

        let httpParams = new HttpParams();
        
        if (params) {
            if (params.page) httpParams = httpParams.set('page', params.page.toString());
            if (params.limit) httpParams = httpParams.set('limit', params.limit.toString());
            if (params.startDate) httpParams = httpParams.set('startDate', params.startDate.toISOString());
            if (params.endDate) httpParams = httpParams.set('endDate', params.endDate.toISOString());
        }

        return this._httpClient.get<any>(
            `${environment.apiUrl}/status-reports/engagements/${engagementId}`,
            { params: httpParams }
        );
    }

    /**
     * Get a single status report by ID
     * @param statusReportId The ID of the status report
     * @returns Observable with the status report
     */
    getStatusReport(statusReportId: string): Observable<any> {
        if (!statusReportId) {
            return of({
                success: false,
                error: 'status report id not provided to service function'
            });
        }
        return this._httpClient.get<any>(`${environment.apiUrl}/status-reports/${statusReportId}`);
    }

    /**
     * Update a status report
     * @param statusReportId The ID of the status report to update
     * @param params The updated data
     * @returns Observable with the updated status report
     */
    updateStatusReport(statusReportId: string, params: object): Observable<any> {
        if (!statusReportId) {
            return of({
                success: false,
                error: 'status report id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/status-reports/${statusReportId}`, params);
    }

    /**
     * Delete a status report
     * @param statusReportId The ID of the status report to delete
     * @returns Observable with the delete operation result
     */
    deleteStatusReport(statusReportId: string): Observable<any> {
        if (!statusReportId) {
            return of({
                success: false,
                error: 'status report id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/status-reports/${statusReportId}`);
    }

    /**
     * Get color-coded status enum values
     */
    getColorCodedStatuses() {
        return {
            GREEN: 'green',
            YELLOW: 'yellow',
            RED: 'red'
        };
    }
}
