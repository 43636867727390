import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormArray, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../core/_services/user.service";
import {AuthService} from "../../../../core/auth/auth.service";
import {environment} from "../../../../../environments/environment";
import {AccountService} from "../../../../core/_services/account.service";
import {RoleService} from "../../../../core/_services/role.service";

export interface DialogData {
    _id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy{
    @ViewChild('signUpNgForm') editProfileNgForm: NgForm;

    editProfileForm: UntypedFormGroup;
    currentUser;

    roleDict: any;
    roleOptions: any;

    accountOptions: any;
    billingRoleOptions: any;

    constructor(
        private _accountService: AccountService,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _userService: UserService,
        private _billingRoleService: RoleService,
        public dialogRef: MatDialogRef<CreateUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {}

    onClose(): void {
        this.dialogRef.close();
    }

    async ngOnInit(): Promise<void> {
        this._accountService.getAccounts({}).subscribe(accounts => {
            this.accountOptions = accounts;
        })

        this.billingRoleOptions = await this._billingRoleService.getRoles({}).toPromise();

        this.roleDict = this._userService.availableRoles();
        this.roleOptions=Object.keys(this.roleDict);
        this.editProfileForm = this._formBuilder.group({
                nameFirst      : ['', Validators.required],
                nameLast    :['', Validators.required],
                email     : ['', [Validators.required, Validators.email]],
                billingRole : [''],
                jiraUsername    :[''],
                jiraAccountIds: this._formBuilder.array([]),
                slackId    :[''],
                slackDailyCheckAlert: false,
                disabled : false,
                role: [''],
                roles: this._formBuilder.array([]),
            }
        );
        // this.editProfileForm.controls.nameFirst.setValue(this.data.nameFirst);
        // this.editProfileForm.controls.nameLast.setValue(this.data.nameLast);
        // this.editProfileForm.controls.email.setValue(this.data.email);
        // this.editProfileForm.controls.billingType.setValue(this.data.billingType);
        // this.editProfileForm.controls.hourlyCost.setValue(this.data.hourlyCost);
        // this.editProfileForm.controls.jiraUsername.setValue(this.data.jiraUsername);
        // this.editProfileForm.controls.jiraAccountId.setValue(this.data.jiraAccountId);
        // this.editProfileForm.controls.slackId.setValue(this.data.slackId);
    }

    ngOnDestroy(): void {
        console.log('destryoed');
    }

    addJiraAccount(accoundId?, jiraUserId?) {
        const jiraAccount = this._formBuilder.group({
            account: [accoundId || '', Validators.required],
            jiraUserId: [jiraUserId || '', Validators.required],
        });

        this.jiraAccountIds.push(jiraAccount);
    }

    // Helper function to remove an item from the jiraAccountIds array
    removeJiraAccount(index: number) {
        this.jiraAccountIds.removeAt(index);
    }

    // Getter for easier access to the jiraAccountIds array
    get jiraAccountIds() {
        return this.editProfileForm.get('jiraAccountIds') as FormArray;
    }

    get roles() {
        return this.editProfileForm.controls["roles"] as FormArray;
    }

    addRole(role) {
        const roleForm = this._formBuilder.group({
            role: [role, Validators.required],
        });
        this.roles.push(roleForm);
        this.roleOptions.splice(this.roleOptions.indexOf(role), 1);
        this.editProfileForm.controls.role.setValue('');
    }

    removeRole(roleIdx: number) {
        this.roleOptions.push(this.editProfileForm.value.roles[roleIdx].role);
        this.roles.removeAt(roleIdx);
    }

    createUser(){
        const roles = this.editProfileForm.value.roles.map(role => {
            return role.role;
        });
        try {
            this._userService.createUser({
                ...this.editProfileForm.value,
                roles,
                jiraAccountIds: this.jiraAccountIds.value,
            }).subscribe(data => {
                console.log(data);
                //TODO if admin2
                this.onClose();
            })
        } catch (error){
            console.log(`err: ${error}`);
        }
    }
}
