import { Route } from '@angular/router';
import {ProjectManagementResolvers} from "./project-management.resolvers";
import {ProjectManagementComponent} from "./project-management.component";

export const dailyReportRoutes: Route[] = [
    {
        path     : '',
        component: ProjectManagementComponent,
        resolve  : {
            data: ProjectManagementResolvers
        }
    }
];
