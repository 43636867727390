<h2 mat-dialog-title>Create New Time Off</h2>

<form [formGroup]="timeOffForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex flex-col gap-4">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="timeOffForm.get('startDate')?.hasError('required')">Start date is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="Choose an end date">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="timeOffForm.get('endDate')?.hasError('required')">End date is required</mat-error>
      </mat-form-field>

      <mat-error *ngIf="timeOffForm.hasError('dateRangeInvalid')">
        End date cannot be before start date
      </mat-error>

      <mat-error *ngIf="timeOffForm.hasError('endDateNotFuture')">
        End date must be in the future
      </mat-error>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Comments</mat-label>
        <textarea matInput formControlName="comments" placeholder="Add any comments about this time off"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="timeOffForm.invalid || isSubmitting">
      <span *ngIf="isSubmitting">Submitting...</span>
      <span *ngIf="!isSubmitting">Submit</span>
    </button>
  </mat-dialog-actions>
</form>
