import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientStatusAssertionService } from '../../../core/_services/client-status-assertion.service';

@Component({
  selector: 'app-status-assertion-dialog',
  templateUrl: './status-assertion-dialog.component.html',
  styleUrls: ['./status-assertion-dialog.component.scss']
})
export class StatusAssertionDialogComponent implements OnInit {
  statusForm: FormGroup;
  statusTypes: any;
  colorCodedStatuses: any;
  dialogTitle: string;
  
  constructor(
    private _formBuilder: FormBuilder,
    private _clientStatusAssertionService: ClientStatusAssertionService,
    public dialogRef: MatDialogRef<StatusAssertionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      engagementId: string, 
      type: string,
      typeName: string
    }
  ) {
    this.statusTypes = this._clientStatusAssertionService.getStatusTypes();
    this.colorCodedStatuses = this._clientStatusAssertionService.getColorCodedStatuses();
    this.dialogTitle = `Create New ${this.formatTypeName(data.typeName)} Status`;
  }

  ngOnInit(): void {
    this.statusForm = this._formBuilder.group({
      status: ['', Validators.required],
      comments: ['', Validators.required]
    });
  }

  formatTypeName(type: string): string {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  onSubmit(): void {
    if (this.statusForm.valid) {
      const formData = {
        engagement: this.data.engagementId,
        type: this.data.type,
        status: this.statusForm.value.status,
        comments: this.statusForm.value.comments
      };
      
      this.dialogRef.close(formData);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
