import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TimeOffService } from '../../../core/_services/time-off.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-shared-time-off-dialog',
  templateUrl: './time-off-dialog.component.html',
  styleUrls: ['./time-off-dialog.component.scss']
})
export class SharedTimeOffDialogComponent implements OnInit {
  timeOffForm: FormGroup;
  isSubmitting = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<SharedTimeOffDialogComponent>,
    private _timeOffService: TimeOffService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.timeOffForm = this._formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      comments: ['']
    }, { validators: this.dateRangeValidator });
  }

  // Custom validator to ensure end date is after start date and in the future
  dateRangeValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const startDate = formGroup.get('startDate')?.value;
    const endDate = formGroup.get('endDate')?.value;

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of day for fair comparison

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      return { 'dateRangeInvalid': true };
    }

    if (endDate && new Date(endDate) < today) {
      return { 'endDateNotFuture': true };
    }
    
    return null;
  }

  onSubmit(): void {
    if (this.timeOffForm.invalid) {
      return;
    }

    this.isSubmitting = true;

    // Get the form values
    const startDateValue = this.timeOffForm.value.startDate;
    const endDateValue = this.timeOffForm.value.endDate;
    
    // Create Date objects from the form values
    const startDate = new Date(startDateValue);
    const endDate = new Date(endDateValue);
    
    // Set the start date to beginning of day and end date to end of day
    // in the user's local timezone
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const timeOffData = {
      startDate: startDate,
      endDate: endDate,
      comments: this.timeOffForm.value.comments
    };

    this._timeOffService.createTimeOff(timeOffData).subscribe(
      (response) => {
        this.isSubmitting = false;
        this._snackBar.open('Time off created successfully', 'Close', {
          duration: 3000
        });
        this._dialogRef.close(true);
      },
      (error) => {
        this.isSubmitting = false;
        this._snackBar.open(error.error?.error || 'Error creating time off', 'Close', {
          duration: 3000
        });
        console.error('Error creating time off:', error);
      }
    );
  }

  cancel(): void {
    this._dialogRef.close();
  }
}
