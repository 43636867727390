import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { ApexOptions } from "ng-apexcharts";
import { ProjectService } from "../../../core/_services/project.service";
import { TimeEntryService } from "../../../core/_services/time-entry.service";
import { Subject, Observable } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import moment from "moment";
import { UserService } from "../../../core/_services/user.service";

@Component({
    selector: 'app-project-report',
    templateUrl: './project-report.component.html',
    styleUrls: ['./project-report.component.scss']
})
export class ProjectReportComponent implements OnInit, OnDestroy {
    monthIndex = 0;
    projects = [];
    users = [];
    userDict = {};
    selectedProjectId: string | null = null;
    selectedProjectName: string | null = null;  // Variable for selected project name
    timeEntries = [];
    totalHours = 0;
    totalEarnings = 0;
    projectedRatio = 1;
    selectedMonth: string = 'Current Month';
    projectControl = new FormControl();
    filteredProjects: Observable<any[]>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    earningsDailyGraphData;
    earningByDate: ApexOptions = {};

    goBackMonths = Array(52).fill(0);

    constructor(
        private _projectService: ProjectService,
        private _userService: UserService,
        private _timeEntryService: TimeEntryService
    ) {}

    ngOnInit(): void {
        this.loadProjects();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // Display function for mat-autocomplete to show project name instead of ID after selection
    displayProject(project: any): string {
        if (!project || !this.projects || this.projects.length === 0) {
            return ''; // Guard against undefined or empty projects array
        }
        return typeof project === 'string'
            ? (this.projects.find(p => p._id === project)?.name || '')
            : project.name;
    }

    // Method to filter projects based on input
    private _filterProjects(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.projects.filter(project => project.name.toLowerCase().includes(filterValue));
    }

    loadProjects(): void {
        this._projectService.getProjects({}).subscribe((projects) => {
            this.projects = projects;
            this.filteredProjects = this.projectControl.valueChanges.pipe(
                startWith(''),
                map(value => (typeof value === 'string' ? value : value.name)),
                map(name => (name ? this._filterProjects(name) : this.projects.slice()))
            );
        });
    }

    onProjectSelected(projectId: string): void {
        const selectedProject = this.projects.find(project => project._id === projectId);
        if (selectedProject) {
            this.selectedProjectId = projectId;
            this.selectedProjectName = selectedProject.name; // Set selected project's name
            this.projectControl.setValue(selectedProject);  // Set control value to full project object
            this.loadReport();
        }
    }

    loadReport(): void {
        this.userDict = {};
        this._userService.getUsers({}).subscribe(users => {
            this.users = users;
            this.users.forEach(user => {
                this.userDict[user._id] = {
                    disabled: user.disabled,
                    name: `${user.nameFirst} ${user.nameLast}`,
                    hours: 0,
                    billingType: user.billingType == 'senior' ? 'seniorBillingRate' : user.billingType == 'mid' ? 'midBillingRate' : 'juniorBillingRate',
                }
            });
        });
        if (!this.selectedProjectId) return;

        const startDate = moment().subtract(this.monthIndex, 'M').startOf('month').format('YYYY-MM-DD');
        const endDate = moment(startDate).add(1, 'M').startOf('month').format('YYYY-MM-DD');
        const daysInMonth = moment(startDate).daysInMonth();
        const daysPassed = this.monthIndex === 0 ? parseInt(moment().format('D')) : daysInMonth;
        this.projectedRatio = daysInMonth / daysPassed;

        this.totalHours = 0;
        this.totalEarnings = 0;

        this.earningsDailyGraphData = {
            labels: Array.from({ length: daysInMonth }, (_, i) => ((parseInt(String(i)) + 1).toString())),
            series: [{ data: new Array(daysInMonth).fill(0), name: "Earnings", type: "line" }],
        };

        this._timeEntryService.getTimeEntryRangeByProject(startDate, endDate, this.selectedProjectId).subscribe((timeEntries) => {
            this.timeEntries = timeEntries;

            timeEntries.forEach((entry) => {
                entry.userName = this.userDict[entry.user] ? this.userDict[entry.user].name : "unknown";
                this.totalHours += entry.timeSpent;
                const cost = entry.cost || 0; // Assuming each entry has a calculated cost
                this.totalEarnings += cost;

                const day = parseInt(entry.timestamp.slice(8, 10));
                this.earningsDailyGraphData.series[0].data[day - 1] += cost;
            });

            this.setupChart();
        });
    }

    setupChart(): void {
        this.earningByDate = {
            chart: { type: 'line', height: '100%' },
            series: this.earningsDailyGraphData.series,
            labels: this.earningsDailyGraphData.labels,
            yaxis: { labels: { formatter: (val) => val.toFixed(2) } },
        };
    }

    loadReportMonth(index: number): void {
        this.monthIndex = index;
        this.selectedMonth = this.getMonthStartUI(index);
        this.loadReport();
    }

    getMonthStartUI(index: number): string {
        const startDate = moment().subtract(index, 'M').startOf('month');
        return startDate.format('MMMM YYYY');
    }

    exportToExcel(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.timeEntries.map((entry) => ({
            User: entry.userName,
            "Time Spent": entry.timeSpent.toFixed(2),
            Project: entry.projectName,
            Description: entry.description,
            Timestamp: moment(entry.timestamp).format('MM/DD/YYYY h:mm a'),
        })));
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'TimeEntries');
        XLSX.writeFile(wb, 'ProjectTimeEntries.xlsx');
    }

    formatDate(date: Date): string {
        return moment(date).format('MM/DD/YYYY');
    }
}
