<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="bg-card">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
            <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12">
                <!-- Avatar and name -->
                <div class="flex flex-auto items-center min-w-0">
                    <!--<div class="flex-0 w-16 h-16 rounded-full overflow-hidden">
                        <img
                            class="w-full h-full object-cover"
                            src="assets/images/avatars/brian-hughes.jpg">
                    </div>-->
                    <div class="flex flex-col min-w-0 ml-4">
                        <ng-container *transloco="let t">
                            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">Monthly Report</div>
                        </ng-container>
                        <!--<div class="flex items-center">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:bell'"></mat-icon>
                            <div class="ml-1.5 leading-6 truncate text-secondary">You have 2 new messages and 15 new tasks</div>
                        </div>-->
                    </div>
                </div>
                <!-- Actions -->
                <!--<div class="flex items-center mt-6 sm:mt-0 sm:ml-2 space-x-3">
                    <button
                        class="bg-accent"
                        mat-flat-button
                        [color]="'accent'">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                        <span class="ml-2">Messages</span>
                    </button>
                    <button
                        mat-flat-button
                        [color]="'primary'">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                        <span class="ml-2">Settings</span>
                    </button>
                </div>-->
            </div>
            <!-- Project selector -->
            <div
                class="relative flex self-start pt-2 pb-1 pl-5 pr-4 cursor-pointer overflow-hidden rounded-t-xl border border-b-0 bg-default"
                matRipple
                [matMenuTriggerFor]="monthsMenu">
                <div class="flex items-center">
                    <div class="overflow-hidden">
                        <div class="font-medium leading-6 truncate">{{selectedMonth}}</div>
                    </div>
                    <div class="flex items-center justify-center pl-2">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                    </div>
                </div>
                <mat-menu
                    #monthsMenu="matMenu"
                    [xPosition]="'before'">
                    <button *ngFor="let month of goBackMonths; let idx = index"
                            mat-menu-item
                            (click)="loadReportMonth(idx)">{{getMonthStartUI(idx)}}
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>

    <!-- Main -->
    <div class="flex-auto border-t -mt-px pt-4 sm:pt-6">
        <div class="w-full max-w-screen-xl mx-auto">
            <!-- Tabs -->
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0" style="padding-left: 19px; padding-right: 19px;">
                <!-- Summary -->
                <!--<div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Summary</div>
                        <div class="ml-2 -mt-2 -mr-3">
                            <button
                                mat-icon-button
                                [matMenuTriggerFor]="summaryMenu">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                            </button>
                            <mat-menu #summaryMenu="matMenu">
                                <button mat-menu-item>Yesterday</button>
                                <button mat-menu-item>2 days ago</button>
                                <button mat-menu-item>3 days ago</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-blue-500">21</div>
                        <div class="text-lg font-medium text-blue-600 dark:text-blue-500">Due Tasks</div>
                        <div class="flex items-baseline justify-center w-full mt-5 text-secondary">
                            <div class="text-md font-medium truncate">Completed:</div>
                            <div class="ml-1.5 text-lg font-semibold">13</div>
                        </div>
                    </div>
                </div>-->
                <!-- Overdue -->
                <!--<div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Overdue</div>
                        <div class="ml-2 -mt-2 -mr-3">
                            <button
                                mat-icon-button
                                [matMenuTriggerFor]="overdueMenu">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                            </button>
                            <mat-menu #overdueMenu="matMenu">
                                <button mat-menu-item>Yesterday</button>
                                <button mat-menu-item>2 days ago</button>
                                <button mat-menu-item>3 days ago</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-red-500">17</div>
                        <div class="text-lg font-medium text-red-600 dark:text-red-500">Tasks</div>
                        <div class="flex items-baseline justify-center w-full mt-5 text-secondary">
                            <div class="text-md font-medium truncate">From yesterday:</div>
                            <div class="ml-1.5 text-lg font-semibold">9</div>
                        </div>
                    </div>
                </div>-->
                <!-- Issues -->
                <!--<div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Issues</div>
                        <div class="ml-2 -mt-2 -mr-3">
                            <button
                                mat-icon-button
                                [matMenuTriggerFor]="issuesMenu">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                            </button>
                            <mat-menu #issuesMenu="matMenu">
                                <button mat-menu-item>Yesterday</button>
                                <button mat-menu-item>2 days ago</button>
                                <button mat-menu-item>3 days ago</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-amber-500">24</div>
                        <div class="text-lg font-medium text-amber-600 dark:text-amber-500">Open</div>
                        <div class="flex items-baseline justify-center w-full mt-5 text-secondary">
                            <div class="text-md font-medium truncate">Closed today:</div>
                            <div class="ml-1.5 text-lg font-semibold">19</div>
                        </div>
                    </div>
                </div>-->
                <!-- Features -->
                <!--<div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Features</div>
                        <div class="ml-2 -mt-2 -mr-3">
                            <button
                                mat-icon-button
                                [matMenuTriggerFor]="featuresMenu">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                            </button>
                            <mat-menu #featuresMenu="matMenu">
                                <button mat-menu-item>Yesterday</button>
                                <button mat-menu-item>2 days ago</button>
                                <button mat-menu-item>3 days ago</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-green-500">38</div>
                        <div class="text-lg font-medium text-green-600 dark:text-green-500">Proposals</div>
                        <div class="flex items-baseline justify-center w-full mt-5 text-secondary">
                            <div class="text-md font-medium truncate">Implemented:</div>
                            <div class="ml-1.5 text-lg font-semibold">16</div>
                        </div>
                    </div>
                </div>-->
                <!-- Github issues summary -->
                <div class="col-span-4 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-col sm:flex-row items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Summary of {{getMonthStartUI(monthIndex)}}</div>
                        <div>
                            <button
                                class="close-off-button"
                                style="margin-right: 14px; border-radius: 25px;
                                background-color: #1e40af; color: white; padding: 14px;
                               border: none; cursor: pointer;
                               transition: background-color 0.3s, box-shadow 0.3s;"
                                (click)="confirmSyncMonth()">
                                Sync
                            </button>
                            <button
                                *ngIf="checkBlockMonth()"
                                class="close-off-button"
                                style="border-radius: 25px; background-color: orangered; color: white; padding: 14px;
                               border: none; cursor: pointer;
                               transition: background-color 0.3s, box-shadow 0.3s;"
                                (click)="confirmBlockMonth()">
                                Close Off
                            </button>
                            <button
                                *ngIf="!checkBlockMonth()"
                                class="close-off-button"
                                disabled
                                style="border-radius: 25px; background-color: #166534; color: white; padding: 14px;
                               border: none;
                               transition: background-color 0.3s, box-shadow 0.3s;"
                                (click)="confirmBlockMonth()">
                                Closed Off
                            </button>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-6 w-full mt-8 sm:mt-4">
                        <!-- New vs. Closed -->
                        <div class="flex flex-col flex-auto">
                            <div class="font-medium text-secondary">Earning by Date ($)</div>
                            <div class="flex flex-col flex-auto">
                                <apx-chart
                                    class="flex-auto w-full h-80"
                                    [chart]="earningByDate.chart"
                                    [colors]="earningByDate.colors"
                                    [dataLabels]="earningByDate.dataLabels"
                                    [grid]="earningByDate.grid"
                                    [labels]="earningByDate.labels"
                                    [legend]="earningByDate.legend"
                                    [plotOptions]="earningByDate.plotOptions"
                                    [series]="earningByDate.series"
                                    [states]="earningByDate.states"
                                    [stroke]="earningByDate.stroke"
                                    [tooltip]="earningByDate.tooltip"
                                    [xaxis]="earningByDate.xaxis"
                                    [yaxis]="earningByDate.yaxis"></apx-chart>
                            </div>
                        </div>
                        <!-- Overview -->
                        <div class="flex flex-col">
                            <div class="font-medium text-secondary">Overview</div>
                            <div class="flex-auto grid grid-cols-4 gap-4 mt-6">
                                <!-- New issues -->
                                <div class="col-span-2 flex flex-col items-center justify-center py-8 px-1 rounded-2xl bg-green-50 text-green-800 dark:bg-white dark:bg-opacity-5 dark:text-indigo-400">
                                    <div class="text-2xl sm:text-4xl font-semibold leading-none tracking-tight">
                                        {{this.earningsDailyGraphData.overview.totalHours}}
                                    </div>
                                    <div class="mt-1 text-sm sm:text-lg font-medium">Hours to Date</div>
                                </div>
                                <!-- Closed -->
                                <div class="col-span-2 flex flex-col items-center justify-center py-8 px-1 rounded-2xl bg-blue-50 text-blue-800 dark:bg-white dark:bg-opacity-5 dark:text-green-500">
                                    <div class="text-2xl sm:text-4xl font-semibold leading-none tracking-tight">
                                        {{this.earningsDailyGraphData.overview.projectedHours}}
                                    </div>
                                    <div class="mt-1 text-sm sm:text-lg font-medium">Projected Hours</div>
                                </div>
                                <!-- New issues -->
                                <div class="col-span-2 flex flex-col items-center justify-center py-8 px-1 rounded-2xl bg-green-50 text-green-800 dark:bg-white dark:bg-opacity-5 dark:text-indigo-400">
                                    <div class="text-2xl sm:text-4xl font-semibold leading-none tracking-tight">
                                        ${{this.earningsDailyGraphData.overview.totalEarnings}}
                                    </div>
                                    <div class="mt-1 text-sm sm:text-lg font-medium">Earnings to Date</div>
                                </div>
                                <!-- Closed -->
                                <div class="col-span-2 flex flex-col items-center justify-center py-8 px-1 rounded-2xl bg-blue-50 text-blue-800 dark:bg-white dark:bg-opacity-5 dark:text-green-500">
                                    <div class="text-2xl sm:text-4xl font-semibold leading-none tracking-tight">
                                        ${{this.earningsDailyGraphData.overview.projectedEarnings}}
                                    </div>
                                    <div class="mt-1 text-sm sm:text-lg font-medium">Projected Earnings</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid col-span-4 gap-8 w-full mt-6 md:mt-8" *ngIf="chartsLoaded">
                    <!-- Project Earnings Distribution -->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">Project Earnings Distribution</div>
                            <!--<div class="ml-2">
                                <button
                                    class="h-6 min-h-6 px-2 rounded-full bg-hover"
                                    mat-button
                                    [matMenuTriggerFor]="conversionMenu">
                                    <span class="font-medium text-sm text-secondary">30 days</span>
                                </button>
                                <mat-menu #conversionMenu="matMenu">
                                    <button mat-menu-item>30 days</button>
                                    <button mat-menu-item>3 months</button>
                                    <button mat-menu-item>9 months</button>
                                </mat-menu>
                            </div>-->
                        </div>
                        <div class="flex flex-col flex-auto mt-6 h-44">
                            <apx-chart
                                class="flex flex-auto items-center justify-center w-full h-full"
                                [chart]="chartProjectEarningDistribution.chart"
                                [colors]="chartProjectEarningDistribution.colors"
                                [labels]="chartProjectEarningDistribution.labels"
                                [plotOptions]="chartProjectEarningDistribution.plotOptions"
                                [series]="chartProjectEarningDistribution.series"
                                [states]="chartProjectEarningDistribution.states"
                                [tooltip]="chartProjectEarningDistribution.tooltip"></apx-chart>
                        </div>
                        <div class="mt-8">
                            <div class="-my-3 divide-y">
                                <ng-container>
                                    <div class="grid grid-cols-4 py-3">
                                        <div class="font-medium text-left text-black">Project</div>
                                        <div class="font-medium text-green-800 text-right">Earnings to Date</div>
                                        <div class="font-medium text-blue-800 text-right">Projected Earnings</div>
                                        <div class="font-medium text-right text-gray-800">Percentage</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let dataset of chartProjectEarningDistribution.series; let i = index">
                                    <div class="grid grid-cols-4 py-3" *ngIf="dataset > 0">
                                        <div class="flex items-center">
                                            <div
                                                class="flex-0 w-2 h-2 rounded-full"
                                                [style.backgroundColor]="chartProjectEarningDistribution.colors[i]"></div>
                                            <div class="ml-3 truncate">{{chartProjectEarningDistribution.labels[i]}}</div>
                                        </div>
                                        <div class="font-medium text-green-600 text-right">~ ${{(totalEarnings * (dataset / 100 )).toFixed(2)}}</div>
                                        <div class="font-medium text-blue-600 text-right">~ ${{(totalEarnings * (dataset / 100 ) * projectedRatio).toFixed(2)}}</div>
                                        <div class="text-right text-secondary">{{dataset}}%</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- Project Time Distribution-->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">Project Time Distribution</div>
                            <!--<div class="ml-2">
                                <button
                                    class="h-6 min-h-6 px-2 rounded-full bg-hover"
                                    mat-button
                                    [matMenuTriggerFor]="conversionMenu">
                                    <span class="font-medium text-sm text-secondary">30 days</span>
                                </button>
                                <mat-menu #conversionMenu="matMenu">
                                    <button mat-menu-item>30 days</button>
                                    <button mat-menu-item>3 months</button>
                                    <button mat-menu-item>9 months</button>
                                </mat-menu>
                            </div>-->
                        </div>
                        <div class="flex flex-col flex-auto mt-6 h-44">
                            <apx-chart
                                class="flex flex-auto items-center justify-center w-full h-full"
                                [chart]="chartProjectTimeDistribution.chart"
                                [colors]="chartProjectTimeDistribution.colors"
                                [labels]="chartProjectTimeDistribution.labels"
                                [plotOptions]="chartProjectTimeDistribution.plotOptions"
                                [series]="chartProjectTimeDistribution.series"
                                [states]="chartProjectTimeDistribution.states"
                                [tooltip]="chartProjectTimeDistribution.tooltip"></apx-chart>
                        </div>
                        <div class="mt-8">
                            <div class="-my-3 divide-y">
                                <ng-container>
                                    <div class="grid grid-cols-4 py-3">
                                        <div class="font-medium text-left text-black">Project</div>
                                        <div class="font-medium text-green-800 text-right">Logged Hours</div>
                                        <div class="font-medium text-blue-800 text-right">Projected Hours</div>
                                        <div class="font-medium text-right text-gray-800">Percentage</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let dataset of chartProjectTimeDistribution.series; let i = index">
                                    <div class="grid grid-cols-4 py-3" *ngIf="dataset > 0">
                                        <div class="flex items-center">
                                            <div
                                                class="flex-0 w-2 h-2 rounded-full"
                                                [style.backgroundColor]="chartProjectTimeDistribution.colors[i]"></div>
                                            <div class="ml-3 truncate">{{projectTimeDistributionGraphData.labels[i]}}</div>
                                        </div>
                                        <div class="font-medium text-green-600 text-right">~ {{(totalHours * (dataset / 100 )).toFixed(2)}} hours</div>
                                        <div class="font-medium text-blue-600 text-right">~ {{(totalHours * (dataset / 100 ) * projectedRatio).toFixed(2)}} hours</div>
                                        <div class="text-right text-secondary">{{dataset}}%</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- User Time Contribution-->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">User Time Contribution</div>
                            <!--<div class="ml-2">
                                <button
                                    class="h-6 min-h-6 px-2 rounded-full bg-hover"
                                    mat-button
                                    [matMenuTriggerFor]="conversionMenu">
                                    <span class="font-medium text-sm text-secondary">30 days</span>
                                </button>
                                <mat-menu #conversionMenu="matMenu">
                                    <button mat-menu-item>30 days</button>
                                    <button mat-menu-item>3 months</button>
                                    <button mat-menu-item>9 months</button>
                                </mat-menu>
                            </div>-->
                        </div>
                        <div class="flex flex-col flex-auto mt-6 h-44">
                            <apx-chart
                                class="flex flex-auto items-center justify-center w-full h-full"
                                [chart]="chartUserTimeContribution.chart"
                                [colors]="chartUserTimeContribution.colors"
                                [labels]="chartUserTimeContribution.labels"
                                [plotOptions]="chartUserTimeContribution.plotOptions"
                                [series]="chartUserTimeContribution.series"
                                [states]="chartUserTimeContribution.states"
                                [tooltip]="chartUserTimeContribution.tooltip"></apx-chart>
                        </div>
                        <div class="mt-8">
                            <div class="-my-3 divide-y">
                                <ng-container>
                                    <div class="grid grid-cols-4 py-3">
                                        <div class="font-medium text-left text-black">User</div>
                                        <div class="font-medium text-green-800 text-right">Logged Hours</div>
                                        <div class="font-medium text-blue-800 text-right">Projected Hours</div>
                                        <div class="font-medium text-right text-gray-800">Percentage</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let dataset of chartUserTimeContribution.series; let i = index">
                                    <div class="grid grid-cols-4 py-3">
                                        <div class="flex items-center">
                                            <div
                                                class="flex-0 w-2 h-2 rounded-full"
                                                [style.backgroundColor]="chartUserTimeContribution.colors[i]"></div>
                                            <div class="ml-3 truncate">{{chartUserTimeContribution.labels[i]}}</div>
                                        </div>
                                        <div class="font-medium text-green-600 text-right">~ {{(totalHours * (dataset / 100)).toFixed(2)}} hours</div>
                                        <div class="font-medium text-blue-600 text-right">~ {{(totalHours * (dataset / 100) * projectedRatio).toFixed(2)}} hours</div>
                                        <div class="font-medium text-right text-secondary">{{dataset}}%</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid col-span-4 gap-8 w-full mt-6 md:mt-8" *ngIf="chartsLoaded">
                    <!-- Project Earnings Distribution -->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">Project Earnings Distribution</div>
                            <!--<div class="ml-2">
                                <button
                                    class="h-6 min-h-6 px-2 rounded-full bg-hover"
                                    mat-button
                                    [matMenuTriggerFor]="conversionMenu">
                                    <span class="font-medium text-sm text-secondary">30 days</span>
                                </button>
                                <mat-menu #conversionMenu="matMenu">
                                    <button mat-menu-item>30 days</button>
                                    <button mat-menu-item>3 months</button>
                                    <button mat-menu-item>9 months</button>
                                </mat-menu>
                            </div>-->
                        </div>
                        <div class="flex flex-col flex-auto mt-6 h-44">
                            <apx-chart
                                class="flex flex-auto items-center justify-center w-full h-full"
                                [chart]="chartProjectEarningDistribution.chart"
                                [colors]="chartProjectEarningDistribution.colors"
                                [labels]="chartProjectEarningDistribution.labels"
                                [plotOptions]="chartProjectEarningDistribution.plotOptions"
                                [series]="chartProjectEarningDistribution.series"
                                [states]="chartProjectEarningDistribution.states"
                                [tooltip]="chartProjectEarningDistribution.tooltip"></apx-chart>
                        </div>
                        <div class="mt-8">
                            <div class="-my-3 divide-y">
                                <ng-container>
                                    <div class="grid grid-cols-4 py-3">
                                        <div class="font-medium text-left text-black">Project</div>
                                        <div class="font-medium text-green-800 text-right">Earnings to Date</div>
                                        <div class="font-medium text-blue-800 text-right">Projected Earnings</div>
                                        <div class="font-medium text-right text-gray-800">Percentage</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let dataset of chartProjectEarningDistribution.series; let i = index">
                                    <div class="grid grid-cols-4 py-3" *ngIf="dataset > 0">
                                        <div class="flex items-center">
                                            <div
                                                class="flex-0 w-2 h-2 rounded-full"
                                                [style.backgroundColor]="chartProjectEarningDistribution.colors[i]"></div>
                                            <div class="ml-3 truncate">{{chartProjectEarningDistribution.labels[i]}}</div>
                                        </div>
                                        <div class="font-medium text-green-600 text-right">~ ${{(totalEarnings * (dataset / 100 )).toFixed(2)}}</div>
                                        <div class="font-medium text-blue-600 text-right">~ ${{(totalEarnings * (dataset / 100 ) * projectedRatio).toFixed(2)}}</div>
                                        <div class="text-right text-secondary">{{dataset}}%</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- Project Time Distribution-->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">Project Time Distribution</div>
                            <!--<div class="ml-2">
                                <button
                                    class="h-6 min-h-6 px-2 rounded-full bg-hover"
                                    mat-button
                                    [matMenuTriggerFor]="conversionMenu">
                                    <span class="font-medium text-sm text-secondary">30 days</span>
                                </button>
                                <mat-menu #conversionMenu="matMenu">
                                    <button mat-menu-item>30 days</button>
                                    <button mat-menu-item>3 months</button>
                                    <button mat-menu-item>9 months</button>
                                </mat-menu>
                            </div>-->
                        </div>
                        <div class="flex flex-col flex-auto mt-6 h-44">
                            <apx-chart
                                class="flex flex-auto items-center justify-center w-full h-full"
                                [chart]="chartProjectTimeDistribution.chart"
                                [colors]="chartProjectTimeDistribution.colors"
                                [labels]="chartProjectTimeDistribution.labels"
                                [plotOptions]="chartProjectTimeDistribution.plotOptions"
                                [series]="chartProjectTimeDistribution.series"
                                [states]="chartProjectTimeDistribution.states"
                                [tooltip]="chartProjectTimeDistribution.tooltip"></apx-chart>
                        </div>
                        <div class="mt-8">
                            <div class="-my-3 divide-y">
                                <ng-container>
                                    <div class="grid grid-cols-4 py-3">
                                        <div class="font-medium text-left text-black">Project</div>
                                        <div class="font-medium text-green-800 text-right">Logged Hours</div>
                                        <div class="font-medium text-blue-800 text-right">Projected Hours</div>
                                        <div class="font-medium text-right text-gray-800">Percentage</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let dataset of chartProjectTimeDistribution.series; let i = index">
                                    <div class="grid grid-cols-4 py-3" *ngIf="dataset > 0">
                                        <div class="flex items-center">
                                            <div
                                                class="flex-0 w-2 h-2 rounded-full"
                                                [style.backgroundColor]="chartProjectTimeDistribution.colors[i]"></div>
                                            <div class="ml-3 truncate">{{projectTimeDistributionGraphData.labels[i]}}</div>
                                        </div>
                                        <div class="font-medium text-green-600 text-right">~ {{(totalHours * (dataset / 100 )).toFixed(2)}} hours</div>
                                        <div class="font-medium text-blue-600 text-right">~ {{(totalHours * (dataset / 100 ) * projectedRatio).toFixed(2)}} hours</div>
                                        <div class="text-right text-secondary">{{dataset}}%</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- Engagements Table -->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">Engagements</div>
                        </div>
                        <div class="mt-8">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                <tr class="border-b">
                                    <th class="col-span-2 font-medium text-left text-black">Engagement</th>
                                    <th class="col-span-2 font-medium text-left text-black">Project</th>
                                    <th class="col-span-1 font-medium text-left text-black">Hours</th>
                                    <th class="col-span-1 font-medium text-left text-black">Budget</th>
<!--                                    <th class="col-span-1 font-medium text-left text-black">Start Date</th>-->
<!--                                    <th class="col-span-1 font-medium text-left text-black">End Date</th>-->
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let engagement of engagements; let i = index">
                                    <tr class="border-b">
                                        <td class="col-span-2 font-medium text-gray-900 text-left">{{engagement.name}}</td>
                                        <td class="col-span-2 font-medium text-gray-900 text-left">{{projectDict[engagement.project].name}}</td>
                                        <td class="col-span-1 font-medium text-left"
                                            [ngClass]="{
                                 'text-green': engagement.currentHours !== undefined && engagement.currentHours !== null && engagement.currentHours < engagement.hourLimit * 0.8,
                                 'text-yellow': engagement.currentHours !== undefined && engagement.currentHours !== null && engagement.currentHours >= engagement.hourLimit * 0.8 && engagement.currentHours <= engagement.hourLimit,
                                 'text-red': engagement.currentHours !== undefined && engagement.currentHours !== null && engagement.currentHours > engagement.hourLimit
                             }">
                                            {{ engagement.currentHours !== undefined && engagement.currentHours !== null ? engagement.hourLimit ? (engagement.currentHours + '/' + engagement.hourLimit) : engagement.currentHours : engagement.hourLimit ? ('Limit: ' + engagement.hourLimit) : '' }}
                                        </td>
                                        <td class="col-span-1 font-medium text-left"
                                            [ngClass]="{
                                 'text-green': engagement.utilizedBudget !== undefined && engagement.utilizedBudget !== null && engagement.utilizedBudget < engagement.budget * 0.8,
                                 'text-yellow': engagement.utilizedBudget !== undefined && engagement.utilizedBudget !== null && engagement.utilizedBudget >= engagement.budget * 0.8 && engagement.utilizedBudget <= engagement.budget,
                                 'text-red': engagement.utilizedBudget !== undefined && engagement.utilizedBudget !== null && engagement.utilizedBudget > engagement.budget
                             }">
                                            {{ engagement.utilizedBudget !== undefined && engagement.utilizedBudget !== null ? engagement.budget ? ('$' + engagement.utilizedBudget + '/$' + engagement.budget) : ('$' + engagement.utilizedBudget) : engagement.budget ? ('Limit: $' + engagement.budget) : '' }}
                                        </td>
<!--                                        <td class="col-span-1 font-medium text-gray-900 text-left">{{formatDate(engagement.startDate)}}</td>-->
<!--                                        <td class="col-span-1 font-medium text-gray-900 text-left">{{formatDate(engagement.endDate)}}</td>-->
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- User Time Contribution-->
                    <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-medium tracking-tight leading-6 truncate">Time Entries</div>
                            <button (click)="exportToExcel()" class="bg-blue-500 text-white rounded px-4 py-2">Export to Excel</button>
                        </div>
                        <div class="mt-8">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                <tr class="border-b">
                                    <th class="col-span-2 font-medium text-left text-black">User</th>
                                    <th class="col-span-1 font-medium text-black text-left">Time Spent</th>
                                    <th class="col-span-2 font-medium text-black text-left">Project</th>
                                    <th class="col-span-2 font-medium text-left text-black">Description</th>
                                    <th class="col-span-2 font-medium text-left text-black">Timestamp</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let dataset of timeEntries; let i = index">
                                    <tr class="border-b">
                                        <td class="col-span-2 font-medium text-gray-900 text-left">{{dataset.userName}}</td>
                                        <td class="col-span-1 font-medium text-gray-900 text-left">{{dataset.timeSpent.toFixed(2)}}</td>
                                        <td class="col-span-2 font-medium text-gray-900 text-left">{{dataset.projectName}}</td>
                                        <td class="col-span-2 font-medium text-gray-900 text-left">{{dataset.description}}</td>
                                        <td class="col-span-2 font-medium text-left text-gray-900">{{formatTime(dataset.timestamp)}}</td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>

</div>
