import { Route } from '@angular/router';
import {DailyReportResolvers} from "./daily-report.resolvers";
import {DailyReportComponent} from "./daily-report.component";

export const dailyReportRoutes: Route[] = [
    {
        path     : '',
        component: DailyReportComponent,
        resolve  : {
            data: DailyReportResolvers
        }
    }
];
