import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';

import { SharedTimeOffDialogComponent } from './dialogs/time-off-dialog/time-off-dialog.component';
import { StatusAssertionDialogComponent } from './dialogs/status-assertion-dialog/status-assertion-dialog.component';
import { StatusReportDialogComponent } from './dialogs/status-report-dialog/status-report-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [
        SharedTimeOffDialogComponent,
        StatusAssertionDialogComponent,
        StatusReportDialogComponent,
        ConfirmDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatSelectModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedTimeOffDialogComponent,
        StatusAssertionDialogComponent,
        StatusReportDialogComponent,
        ConfirmDialogComponent,
    ]
})
export class SharedModule {}
