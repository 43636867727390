import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApexOptions} from "ng-apexcharts";
import {Subject} from "rxjs";
import {ProjectService} from "../../../core/_services/project.service";
import {TimeEntryService} from "../../../core/_services/time-entry.service";
import {UserService} from "../../../core/_services/user.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EditProjectComponent} from "../../../layout/common/project/edit-project/edit-project.component";

@Component({
  selector: 'app-weekly-report',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss']
})
export class ProjectManagementComponent implements OnInit, OnDestroy
{
    dayIndex = 0;

    projects;
    users;
    userDict;
    projectDict;
    timeEntries;
    totalHours;
    totalEarnings;
    projectedRatio;

    alphabeticalAscending: Boolean = true;

    goBackDays = Array(52).fill(0);
    selectedDay: string = 'Current Month';

    chartsLoaded = false;

    //graphs
    earningsDailyGraphData;
    userTimeContributionGraphData;
    projectTimeDistributionGraphData;
    projectEarningDistributionGraphData;

    earningByDate: ApexOptions = {};


    // template variables below
    data: any;

    chartProjectEarningDistribution: ApexOptions;
    chartProjectTimeDistribution: ApexOptions;
    chartUserTimeContribution: ApexOptions;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public dialog: MatDialog,
        private _projectService: ProjectService,
        private _timeEntryService: TimeEntryService,
        private _userService: UserService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loadProjects();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    loadProjects(){
        this._projectService.getProjects({}).subscribe(projects => {
            this.projects = projects;
        });
    }

    filteredProjects(){
        if (this.alphabeticalAscending){
            this.projects.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        } else {
            this.projects.sort(function (a, b) {
                if (a.name < b.name) {
                    return 1;
                }
                if (a.name > b.name) {
                    return -1;
                }
                return 0;
            });
        }


        return this.projects;
    }

    getDayStartUI(index?){
        let startDate;
        if(index){
            startDate = moment().add(-index, 'd').startOf('day');
        } else {
            startDate = moment().startOf('day');
        }
        return startDate.format('MMM Do YYYY');
    }
    getDayStartQuery(index?){
        let startDate;
        if(index){
            startDate = moment().add(-index, 'd').startOf('day');
        } else {
            startDate = moment().startOf('day');
        }
        return startDate.format('YYYY-MM-DD');
    }

    getProjectDetail(id){
        const dialogRef = this.dialog.open(EditProjectComponent, {
            width: '50%',
            data: {
                ...this.projects.find(project => {
                    return project._id == id;
                }),
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.loadProjects();
        });
    }

    createProject(){
        const dialogRef = this.dialog.open(EditProjectComponent, {
            width: '50%',
            data: {
                formType: "new",
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.loadProjects();
        });
    }

    roleTabSelect($event){
        this.dayIndex += $event.index == 1 ? 1 : -1;
    }

    // Template below

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fix the SVG fill references. This fix must be applied to all ApexCharts
     * charts in order to fix 'black color on gradient fills on certain browsers'
     * issue caused by the '<base>' tag.
     *
     * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
     *
     * @param element
     * @private
     */
    private _fixSvgFill(element: Element): void
    {
        // Current URL
        const currentURL = this._router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _loadPieCharts = async() => {
        await Promise.all(Object.keys(this.projectDict).map(key => {
            this.projectEarningDistributionGraphData.labels.push(this.projectDict[key].name);
            this.projectTimeDistributionGraphData.labels.push(this.projectDict[key].name);
            this.projectEarningDistributionGraphData.series.push(parseFloat((this.projectDict[key].cost / this.totalEarnings * 100).toFixed(2)));
            this.projectTimeDistributionGraphData.series.push(parseFloat((this.projectDict[key].hours / this.totalHours * 100).toFixed(2)));
        }));

        await Promise.all(Object.keys(this.userDict).map(key => {
            this.userTimeContributionGraphData.labels.push(this.userDict[key].name);
            this.userTimeContributionGraphData.series.push(parseFloat((this.userDict[key].hours / this.totalHours * 100).toFixed(2)));
        }));

        console.log(this.projectEarningDistributionGraphData);
        console.log(this.projectTimeDistributionGraphData);
        console.log(this.userTimeContributionGraphData);
    }
}

