import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from "../../../environments/environment";
import { CommonUtilitiesService } from "./common-utilities.service";

@Injectable({
    providedIn: 'root'
})
export class TimeOffService {
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}

    /**
     * Create a new time off record for the current user
     * @param params The time off data (startDate, endDate, comments)
     * @returns Observable with the created time off record
     */
    createTimeOff(params: object): Observable<any> {
        return this._httpClient.post<any>(`${environment.apiUrl}/time-offs/`, params);
    }

    /**
     * Get all current and future time off records for a specific user
     * @param userId The ID of the user
     * @returns Observable with the user's time off records
     */
    getUserTimeOffs(userId: string): Observable<any> {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/time-offs/users/${userId}`
        );
    }

    /**
     * Get all current and future time off records for all users in an engagement
     * @param engagementId The ID of the engagement
     * @returns Observable with the time off records for users in the engagement
     */
    getEngagementTimeOffs(engagementId: string): Observable<any> {
        if (!engagementId) {
            return of({
                success: false,
                error: 'engagement id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/time-offs/engagements/${engagementId}`
        );
    }

    /**
     * Delete a time off record
     * @param timeOffId The ID of the time off record to delete
     * @returns Observable with the delete operation result
     */
    deleteTimeOff(timeOffId: string): Observable<any> {
        if (!timeOffId) {
            return of({
                success: false,
                error: 'time off id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/time-offs/${timeOffId}`);
    }
}
