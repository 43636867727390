import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment'; // Import the moment library

@Component({
    selector: 'app-block-month-dialog',
    templateUrl: './sync-month-dialog.component.html',
    styleUrls: ['./sync-month-dialog.component.scss']
})

export class SyncMonthDialogComponent implements OnInit {
    formattedDate: string; // To store the formatted date

    constructor(
        public dialogRef: MatDialogRef<SyncMonthDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
    }

    formatDate(isoDate) {
        const formatted = moment(isoDate).format('MM/DD/YYYY HH:mm'); // Format the ISO date
        return formatted;
    }

    onClose(result: boolean): void {
        this.dialogRef.close(result);
    }
}
