import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {of} from 'rxjs';
import {CommonUtilitiesService} from "./common-utilities.service";
import {AuthService} from "../auth/auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RoleService
{
    currentRole;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,

        private _commonUtilitiesService: CommonUtilitiesService,
    ) {}

    getRole(id: any, params: object) {
        if (!id) {
            return of({
                success: false,
                error: 'role id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/roles/${id}`
        );
    }

    getRoles(params: object) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/roles/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    createRole(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/roles/`, params);
    }

    updateRole(roleId: string, params: object) {
        if (!roleId) {
            return of({
                success: false,
                error: 'role id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/roles/${roleId}`, params);
    }

    patch(roleId: string, params: object)
    {
        if (!roleId) {
            return of({
                success: false,
                error: 'role id not provided to service function'
            });
        }
        return this._httpClient.patch<any>(`${environment.apiUrl}/roles/${roleId}`, params);
    }

    deleteRole(roleId: string) {
        if (!roleId) {
            return of({
                success: false,
                error: 'role id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/roles/${roleId}`);
    }
}
